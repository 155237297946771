import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { MasterContext } from "./MasterContext";
import { AuthContext } from "./AuthContext";
import useFetchWithAuth from '../hooks/useFetchWithAuth'

export const RequestContext = createContext();

const RequestContextProvider = ({ children }) => {
  const [allServices, setAllServices] = useState([]);
  const [service, setService] = useState("");
  const [address, setAddress] = useState("");
  const [unit, setUnit] = useState("");
  const [description, setDescription] = useState("");
  const [answers, setAnswers] = useState([]);
  const [proPicked, setProPicked] = useState([]);
  const [expectedDate, setExpectedDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [moreProviders, setMoreProviders] = useState(true)

  const { master } = useContext(MasterContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    try {
      const myAddress = JSON.parse(localStorage.getItem("address"));
      if (myAddress) setAddress(myAddress);

      const myService = JSON.parse(localStorage.getItem("service"));
      if (myService) setService(myService);

      const myExpectedDate = JSON.parse(localStorage.getItem("expectedDate"));
      if (myExpectedDate) setExpectedDate(myExpectedDate);

      const myExpiryDate = JSON.parse(localStorage.getItem("expiryDate"));
      if (myExpiryDate) setExpiryDate(myExpiryDate);

      const myDescription = localStorage.getItem("description");
      if (myDescription) setDescription(myDescription);

      const myUnit = localStorage.getItem("unit");
      if (myUnit) setUnit(myUnit);

      const myMoreProviders = localStorage.getItem("moreProviders");
      if (myMoreProviders) setMoreProviders(myMoreProviders);

      const myServerServices = JSON.parse(
        localStorage.getItem("serverServices")
      );
      if (myServerServices) setAllServices(myServerServices);
    } catch (e) {
      console.error("Error loading data from localStorage:", e);
    }
  }, []);

  const fetchWithAuth = useFetchWithAuth();

  /* Begin of Common Functions */
  const fetchAllServices = useCallback(async () => {
    try {
      const response = await fetch("/api/servicenoauth", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const serviceList = await response.json();
      if (response.ok) {
        setAllServices(serviceList);
        localStorage.setItem("serverServices", JSON.stringify(serviceList));
      }
    } catch (error) {
      console.error("Failed to fetch services:", error);
    }
  }, []);
  /* End of Common Functions */

  /* Begin of Functions for User */
  const sendUserRequest = useCallback(
    async (body) => {
      if (!user || !body) return;
      try {
        const response = await fetchWithAuth(`/api/userRequest/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify(body),
        });
        if (response.ok) {
          const json = await response.json();
          return json;
        }
      } catch (error) {
        console.error(error);
        return { error };
      }
    },
    [user]
  );

  const setContextUnit = (value) => {
    setUnit(value)
    localStorage.setItem("unit", value)
  }
  const setContextMoreProviders = (value) => {
    if(value !== null && value !== undefined){
      setMoreProviders(value)
      localStorage.setItem("moreProviders", value)
    }
  }
  const setContextAddress = (value) => {
    setAddress(value)
    localStorage.setItem("address", JSON.stringify(value))
  }
  const setContextService = (value) => {
    if (value != null) {
      setService(value)
      localStorage.setItem("service", JSON.stringify(value))
    }
  }
  const setContextExpectedDate = (value) => {
    setExpectedDate(value)
    localStorage.setItem("expectedDate", JSON.stringify(value))
  }
  const setContextExpiryDate = (value) => {
    setExpiryDate(value)
    localStorage.setItem("expiryDate", JSON.stringify(value))
  }

  const setContextDescription = (value) => {
    setDescription(value)
    localStorage.setItem("description", value)
  }

  const removeAddressUnit = () => {
    setUnit("")
    localStorage.removeItem("unit")
  };
  const removeDescription = () => {
    setDescription("");
    localStorage.removeItem("description");
  };

  const removeAddress = () => {
    setAddress("");
    localStorage.removeItem("address");
  };
  const clearService = () => {
    setService("");
    localStorage.removeItem("service");
  };

  const removeExpectedDate = () => {
    setExpectedDate("");
    localStorage.removeItem("expectedDate");
  };

  const removeMoreProviders = () => {
    setMoreProviders("");
    localStorage.removeItem("moreProviders");
  };

  const removeExpiryDate = () => {
    setExpiryDate("");
    localStorage.removeItem("expiryDate");
  };
  /* End of Functions for User */

  /* Begin of Functions for Provider */

  /* End of Functions for Provider */

  /*Begin of Functions for Master*/
  const addService = useCallback(
    async (json) => {
      if (!master) return;
      try {
        const response = await fetch("api/services/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${master.token}`,
          },
          body: JSON.stringify(json),
        });
        if (response.ok) {
          console.log(`Service added successfully`);
          fetchAllServices();
        }
      } catch (error) {
        console.error("Could not add a Service", error);
      }
    },
    [fetchAllServices, master]
  );

  const updateOneService = useCallback(
    async (json) => {
      if (!master || !json) return;
      try {
        const response = await fetch(`api/services/${json._id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${master.token}`,
          },
          body: JSON.stringify(json),
        });
        if (response.ok) {
          console.log("Item updated successfully");
          fetchAllServices();
        }
      } catch (error) {
        console.error("Could not update item", error);
      }
    },
    [fetchAllServices, master]
  );

  const addSeveralServices = useCallback(
    async (json) => {
      if (!master) return;
      try {
        const response = await fetch("api/services/several", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${master.token}`,
          },
          body: JSON.stringify(json),
        });
        if (response.ok) {
          console.log(`Services added successfully`);
          fetchAllServices();
        }
      } catch (error) {
        console.error("Could not send json to Server", error);
      }
    },
    [fetchAllServices, master]
  );

  const removeContextService = (id) => {
    const newArray = allServices.filter((item) => item._id !== id);
    setAllServices(newArray);
    localStorage.setItem("serverServices", JSON.stringify(newArray));
  };

  const deleteOneService = async (id) => {
    if (!master) return;
    try {
      const response = await fetch(`/api/services/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${master.token}`,
        },
      });

      if (response.ok) {
        removeContextService(id);
      } else {
        console.error("Failed to delete service:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting service:", error);
    }
  };
  /* End of Functions for Master */

  return (
    <RequestContext.Provider
      value={{
        service,
        setContextService,
        allServices,
        setAllServices,
        fetchAllServices,
        addService,
        updateOneService,
        addSeveralServices,
        sendUserRequest,
        deleteOneService,
        removeContextService,
        removeService: clearService,
        address,
        setContextAddress,
        moreProviders,
        setContextMoreProviders,
        removeAddress,
        removeAddressUnit,
        expectedDate,
        setContextExpectedDate,
        expiryDate,
        setContextExpiryDate,
        description,
        setContextDescription,
        removeDescription,
        removeExpectedDate,
        removeExpiryDate,
        removeMoreProviders,
        answers,
        setAnswers,
        proPicked,
        setProPicked,
        unit,
        setContextUnit
      }}
    >
      {children}
    </RequestContext.Provider>
  );
};

export default RequestContextProvider;
