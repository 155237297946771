import React, { createContext, useEffect, useState, useCallback } from "react";
import useFetchWithAuth from '../hooks/useFetchWithAuth'

export const ProviderContext = createContext();

const ProviderContextProvider = ({ children }) => {
  const [provider, setProvider] = useState(null);
  const [providerProfile, setProviderProfile] = useState(null);

  useEffect(() => {
    const myProvider = JSON.parse(localStorage.getItem("provider"));
    const myProviderProfile = JSON.parse(
      localStorage.getItem("providerProfile")
    );
    if (myProvider) {
      setProvider({
        email: myProvider.email,
        token: myProvider.token,
      });
    }
    if (myProviderProfile) {
      setProviderProfile(myProviderProfile);
    }
  }, []);

  const fetchWithAuth = useFetchWithAuth();


  const login = useCallback(async (email,password) => {
    const response = await fetch("/api/provider/login", {
      method: "POST",
      body: JSON.stringify({email,password}),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json = await response.json();
    if (!response.ok) {
      return json;
    } else {
      localStorage.setItem("provider", JSON.stringify(json));
      setProvider(json);
      getProviderProfile(json.token);
    }
    
  }, []);

  const signup = useCallback(
    async (email, password, first_name, last_name, company) => {
      const response = await fetch("/api/provider/signup", {
        method: "POST",
        body: JSON.stringify({
          email,
          password,
          first_name,
          last_name,
          company,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      if (response.ok) {
        login(email, password);
      } else {
        return json;
      }
    },
    [login]
  ); // Correct as is, assuming no external dependencies

  const logout = useCallback(() => {
    localStorage.removeItem("provider");
    localStorage.removeItem("providerProfile");
    setProvider(null);
    setProviderProfile(null);
  }, []);

  const getProviderProfile = useCallback(
    async (token) => {
      try {
        const response = await fetch(`/api/provider/profile/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          const profileData = data.user;
          setProviderProfile(profileData);
          localStorage.setItem("providerProfile", JSON.stringify(profileData));
        }
      } catch (e) {
        console.error({ error: e });
      }
    },
    [provider]
  );

  const updateProviderData = useCallback(
    async (provider_id, provider_token, data) => {
      try {
        const response = await fetchWithAuth(`/api/provider/profile/${provider_id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${provider_token}`,
          },
          body: JSON.stringify(data),
        });
        if (response.status === 401) {
          logout()
        }
        if (response.ok) {
          const data = await response.json();
          const updatedProfile = data.user;
          setProviderProfile(updatedProfile);
          localStorage.setItem(
            "providerProfile",
            JSON.stringify(updatedProfile)
          );
          return updatedProfile;
        } else {
          console.error("Failed to update provider data:", response.statusText);
          return null;
        }
      } catch (e) {
        console.error("Error updating provider data:", e);
        return null;
      }
    },
    []
  );

  const newPassword = useCallback(
    async (old_password, new_password) => {
      if (!providerProfile || !providerProfile._id) {
        console.error("Provider profile is undefined or missing _id");
        return {
          success: false,
          message: "Provider profile is undefined or missing _id",
        };
      }
      try {
        const response = await fetch(
          `/api/provider/pwd/${providerProfile._id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${provider?.token}`,
            },
            body: JSON.stringify({ old_password, new_password }),
          }
        );
        if (response.status === 401) {
          logout()
        }
        if (response.ok) {
          return { success: true };
        } else {
          const data = await response.json();
          return {
            success: false,
            message: data.error || "Failed to change password",
          };
        }
      } catch (e) {
        console.error("Error changing password:", e);
        return { success: false, message: e.toString() };
      }
    },
    [provider, providerProfile]
  ); // Correctly includes dependencies

  return (
    <ProviderContext.Provider
      value={{
        provider,
        login,
        logout,
        signup,
        providerProfile,
        setProviderProfile,
        newPassword,
        updateProviderData,
        getProviderProfile,
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};

export default ProviderContextProvider;
