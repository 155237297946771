import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { WorkoutContext } from "../../context/WorkoutContext";
import Button from '@mui/material/Button'
import { ProjectContext } from "../../context/ProjectContext";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
import { CurrentContext } from "../../context/CurrentContext";

const NavbarClient = ({color }) => {
  const { logout, user } = useContext(AuthContext);
  const { setAllWorkouts } = useContext(WorkoutContext);
  const {logoutProjects} = useContext(ProjectContext)
  const {changeIsClient} = useContext(CurrentContext)

  const {t} = useTranslation()

  const navigate = useNavigate()

  const handleLogout = () => {
    setAllWorkouts([]);
    logoutProjects()
    logout();
  };

  const handleClick = ()=>{
    changeIsClient(false)
    navigate('/homeproviders')
  }

  return (
    <header>
      <div className="container header-maestro">
        <Link to="/">
        <p style={{textAlign:'left'}}> 
          <img src={require(`../../images/logo/mc-${color}.png`)} className="logo-mobile" alt="Maestro Clave logo" />
          </p>
        </Link>
        <nav>
          {user ? (
            <div>
              <div>
                <span className={`nav-email${color}`}  >{user.email}</span>
                <Button 
                onClick={handleLogout} 
                variant="outlined"
                size="small"
                margin="normal"
                color="primary"
                className={`log-out-button${color}`}
                >{t("nav.logout")}</Button>
              </div>
            </div>
          ) : (
            <div>
              <Link to="/login">
              <Button 
                variant="contained"
                size="small"
                margin="normal"
                color="primary"
                className={`log-in-button${color}`}
                >{t("nav.login")}</Button>
              </Link>
              <Link to="/signup">
                <Button 
                style={{marginLeft:'8px'}}
                variant="outlined"
                size="small"
                margin="normal"
                color="primary"
                className={`log-out-button${color}`}
                >{t("nav.signup")}</Button>
                </Link>
            </div>
          )}
          <div>
            <Button
            variant="outlined"
            size="small"
            margin="normal"
            color="primary"
            className={`log-out-button${color} mt-3 w-100 ml-0 btn btn-link`}
            onClick={handleClick}> {t('home_client.provider_button')}</Button>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default NavbarClient;
