import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion'
import { MobileDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import 'dayjs/locale/es'
import 'dayjs/locale/pt-br'; // Brazilian Portuguese
import { sendEstimate,updateEstimate } from '../../api/api';


const EstimateEdit = ({token, userRequestId, estimate, getCurrentEstimate=()=>{} , setEditing=()=>{}} ) => {
    const { t, i18n } = useTranslation()

    const [labourDesc, setLabourDesc] = useState(estimate?.labour_desc)
    const [labourValue, setLabourValue] = useState(estimate?.labour_value)
    const [suppliesDesc, setSuppliesDesc] = useState(estimate?.supplies_desc)
    const [suppliesValue, setSuppliesValue] = useState(estimate?.supplies_value)
    const [taxesDesc, setTaxesDesc] = useState(estimate?.taxes_desc)
    const [taxesValue, setTaxesValue] = useState(estimate?.taxes_value)
    const [otherValue, setOtherValue] = useState(estimate?.others_value)
    const [otherDesc, setOtherDesc] = useState(estimate?.others_desc)
    const [terms, setTerms] = useState(estimate?.terms)
    const [expiryDate, setExpiryDate] = useState( dayjs().add(10, 'day'))
    const [locale, setLocale] = useState(i18n.language);

    useEffect(() => {
        const newLocale = i18n.language === 'es' ? 'es' : i18n.language === 'br' ? 'pt-br' : 'en';
        setLocale(newLocale);
    }, [i18n.language]);

    const itemVariants = {
        hidden: { opacity: 0, x: -10 }, // Initial state of the item
        visible: i => ({
            opacity: 1,
            x: 0,
            transition: {
                delay: i * 0.2, // Delay each item based on its index
            },
        }),
    };

    const handleSendEstimate = async (e)=>{
        e.preventDefault()
        const estimateData ={
        userRequestId,
        labour_desc: labourDesc,
        labour_value: labourValue,
        supplies_desc: suppliesDesc,
        supplies_value: suppliesValue,
        others_desc: otherDesc,
        others_value: otherValue,
        taxes_desc: taxesDesc,
        taxes_value: taxesValue,
        terms,
        expiry_date: expiryDate,
        accepted:null,
        sent: new Date(),
        }
        if(estimate && estimate._id){
            await updateEstimate(estimateData,estimate._id,token)
            setEditing(false)
        }else{
            await sendEstimate(estimateData,token)
            setEditing(false)
        }
        getCurrentEstimate() 
      }

    return (<form onSubmit={(e)=>{handleSendEstimate(e)}}  className='overflow-scroll pt-2' >
        <motion.div
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            custom={1}
            className='d-flex flex-row align-items-center'>
            <div className='col-8 pe-1'>
                <TextField
                    required={labourValue}
                    multiline
                    rows={2}
                    variant="outlined"
                    label={t("estimate.labour_desc")}
                    size='small'
                    fullWidth
                    value={labourDesc}
                    onChange={(e) => setLabourDesc(e.target.value)}
                    className='my-1'
                />
            </div>
            <div className='col-4'>

                <TextField
                    type='number'
                    variant="outlined"
                    label={t("estimate.value")}
                    size='large'
                    fullWidth
                    value={labourValue}
                    onChange={(e) => setLabourValue(e.target.value)}
                    className='my-1'
                />
            </div>
        </motion.div>
        <motion.div
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            custom={1.5}
            className='d-flex flex-row align-items-center'>
            <div className='col-8 pe-1'>

                <TextField
                    required={suppliesValue}
                    multiline
                    rows={2}
                    variant='outlined'
                    label={t("estimate.supplies_desc")}
                    size='small'
                    fullWidth
                    value={suppliesDesc}
                    onChange={(e) => setSuppliesDesc(e.target.value)}
                    className='my-1'
                />
            </div>
            <div className='col-4'>

                <TextField
                    type='number'
                    variant="outlined"
                    label={t("estimate.value")}
                    size='large'
                    fullWidth
                    value={suppliesValue}
                    onChange={(e) => setSuppliesValue(e.target.value)}
                    className='my-1'
                />
            </div>
        </motion.div>
        <motion.div
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            custom={2}
            className='d-flex flex-row'>
            <div className='col-8 pe-1'>

                <TextField
                    required={otherValue}
                    variant="outlined"
                    label={t("estimate.other")}
                    size='small'
                    fullWidth
                    value={otherDesc}
                    onChange={(e) => setOtherDesc(e.target.value)}
                    className='my-1'
                />
            </div>
            <div className='col-4'>

                <TextField
                    type='number'
                    variant="outlined"
                    label={t("estimate.value")}
                    size='small'
                    fullWidth
                    value={otherValue}
                    onChange={(e) => setOtherValue(e.target.value)}
                    className='my-1'
                />
            </div>
        </motion.div>
        <motion.div
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            custom={2.5}
            className='d-flex flex-row'>
            <div className='col-8 pe-1'>

                <TextField
                    required={taxesValue}
                    variant="outlined"
                    label={t("estimate.taxes")}
                    size='small'
                    fullWidth
                    value={taxesDesc}
                    onChange={(e) => setTaxesDesc(e.target.value)}
                    className='my-1'
                />
            </div>
            <div className='col-4'>

                <TextField
                    type='number'
                    variant="outlined"
                    label={t("estimate.value")}
                    size='small'
                    fullWidth
                    value={taxesValue}
                    onChange={(e) => setTaxesValue(e.target.value)}
                    className='my-1'
                />
            </div>
        </motion.div>
        <motion.div
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            custom={2.5}
            className='d-flex flex-row'>
            <div className='col-8 pe-1'>

                <TextField
                    variant="outlined"
                    label={t("estimate.terms")}
                    size='medium'
                    fullWidth
                    value={terms}
                    onChange={(e) => setTerms(e.target.value)}
                    className='my-1'
                />
            </div>
            <div className='col-4'>

                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                    <MobileDatePicker
                        size='small'
                        label={t("estimate.expiry_date")}
                        value={expiryDate}
                        onChange={(newValue) => setExpiryDate(newValue)}
                        disablePast
                        defaultValue={dayjs().add(10, 'day')}
                        className='my-1'
                        renderInput={(params) => <TextField {...params} className='my-1' />}
                    />
                </LocalizationProvider>
            </div>

        </motion.div>

        <motion.div
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            custom={3}
            className='d-flex flex-row justify-content-between mt-3'>
            <div className='col-8'>
                <div className=' d-flex flex-row justify-content-between rounded disabled  p-3 h-100'>
                    <span className='text-dark'>{t("estimate.total")}</span>
                    <span className='fw-bolder text-gray-700 fs-4'> $ {parseFloat(taxesValue || 0) + parseFloat(labourValue || 0) + parseFloat(suppliesValue || 0) + parseFloat(otherValue || 0)} </span>
                </div>
            </div>
            <div className='col-4'>

                <motion.button
                    whileHover={{ scale: 0.98, rotate: '0.2deg' }}
                    whileTap={{ scale: 0.95, rotate: '0.4deg' }}
                    className='btn btn-sm btn-mc w-100 d-block' 
                    style={{textAlign: 'center'}}
                    >{t("estimate.send")}
                    </motion.button>
            </div>
        </motion.div>

        <motion.div
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            custom={3.5}
            className='d-flex flex-row justify-content-end rounded p-3 mt-3'>

        </motion.div>

    </form>);
}

export default EstimateEdit;