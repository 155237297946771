import React from 'react';
import { useTranslation } from 'react-i18next';
import PlanCard from './PlanCard';

const MyPlan = () => {
  
  const { t } = useTranslation();
  const plans = [
    {
      id: 1,
      name: t("plan.basic"),
      price: `$29.99/${t("plan.month")}`,
      features: [
        t("plan.basic_listing"),
        t("plan.basic_leads"),
        t("plan.basic_other")
      ],
    },
    {
      id: 2,
      name: t("plan.standard"),
      price: `$49.99/${t("plan.month")}`,
      features: [
        t("plan.standard_listing"),
        t("plan.standard_leads"),
        t("plan.standard_other")
      ],
    },
    {
      id: 3,
      name: t("plan.premium"),
      price: `$99.99/${t("plan.month")}`,
      features: [
        t("plan.premium_listing"),
        t("plan.premium_leads"),
        t("plan.premium_other")
      ],
    },
  ];

    return (
      <div className="row">
      {plans.map((plan) => (
        <PlanCard key={plan.id} plan={plan} />
      ))}
    </div>
    );
}
 
export default MyPlan;