import React from "react";

const IconButtonFullWidth = ({ label, data, icon, color }) => {
  return (
    <span
      className={`btn btn-icon-${color} btn-text-${color} btn-light w-100 mb-2 d-flex flex-row justify-content-between align-items-center fs-7`}
    >
      <div>
        <i className="material-symbols-outlined fs-1">{icon}</i>
        {label}
      </div>
      <span className="fs-7">
        {data}
        </span>
    </span>
  );
};

export default IconButtonFullWidth;
