import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../context/ProjectContext";
import formatDistancetoNow from "date-fns/formatDistanceToNow";
import IconButtonFullWidth from "./IconButtonFullWidth";
import { enUS, es, ptBR } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import {motion} from 'framer-motion'
import { ExpectedDate } from '../../data/dummyData';



const UserProjectCard = ({ project, label }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const locales = { en: enUS, es: es , br: ptBR}; // Add more locales as needed
  const dateFnsLocale = locales[i18n.language] || enUS; // Default to English if locale not found

  const { setCurrentProject, removeOneProject } = useContext(ProjectContext);
  const expectedDates= ExpectedDate()


  const handleClick = () => {
    setCurrentProject(project._id);
    navigate("/requestoptions");
  };

  const deleteThisProject = () => {
    removeOneProject(project._id)
  };

  return (
      <div className="card card-flush mb-8 h-100 ">
        <div className="card-header ">
          <div className="d-flex flex-column">

          <h3 className="card-title text-mc">{label}</h3>
          {project && project.address && project.address.formatted_address && <span className="text-muted fs-8">{project.address.formatted_address}</span>}
          </div>
        </div>
        <div className="card-body">
          {/* <IconButtonFullWidth
            label={project.address.formatted_address}
            icon="home_pin"
            color="gray-700"
          /> */}
          {project.unit && (
            <IconButtonFullWidth
            label={t("profile.unit")}
              data={project.unit}
              icon="apartment"
              color="gray-700"
            />
          )}
          {project && project.expected_date && <IconButtonFullWidth
          label={t("home_provider.expected_date")}
            data={expectedDates[project.expected_date].label}
            icon="calendar_month"
            color="gray-700"
          /> }
          {project.description && (
            <IconButtonFullWidth
            label={t("profile.description")}
            data={project.description}
            icon="edit_note"
            color="gray-700"
            />
            )}
          <IconButtonFullWidth
            label={t("request.requested")}
            data={formatDistancetoNow(new Date(project.createdAt), {
              addSuffix: true,
              locale: dateFnsLocale
            })}
            icon="timer"
            color="gray-700"
            />
            {project.expiry_date && (
              <IconButtonFullWidth
                label={t("home_provider.expiry_date")}
                data={formatDistancetoNow(new Date(project.expiry_date), {
                  addSuffix: true,
                  locale: dateFnsLocale
                })}
                icon="timer"
                color="gray-700"
              />
            )}
          <div className="mt-6">

          <motion.span
              whileHover={{
                scale:0.98,
                rotate:'2deg'
              }}
              whileTap={{
                scale:0.94,
                rotate:'4deg'
              }}

            className="btn btn-sm btn-mc float-end text-capitalize"
            onClick={handleClick}
            >
            {t("request.view_options")}
          </motion.span>
          <span
            className="btn btn-sm btn-light text-gray-700 float-end mx-3 text-capitalize"
            onClick={deleteThisProject}
            >
            {t("request.archive")}
          </span>
            </div>
        </div>
      </div>
  );
};

export default UserProjectCard;
