import { TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectContext } from "../../context/ProjectContext";

const RespondReview = ({ userRequest , getReviews=()=>{}}) => {
  const [reply, setReply] = useState("");
  const { t } = useTranslation();
  const { updateUserRequest } = useContext(ProjectContext);

  const handleSend = async () => {
    const review_response_date = new Date();
    await updateUserRequest({
      _id: userRequest,
      review_response: reply,
      review_response_date,
    });
    await getReviews()
  };

  return (
    <div className="mt-6">
      <TextField
        multiline
        rows={4}
        fullWidth
        value={reply}
        onChange={(e) => setReply(e.target.value)}
        label={t("request.respond_review")}
      />
      <div className="d-flex justify-content-end">
        <div onClick={() => handleSend()} className="btn btn-sm btn-mc mt-4">
          <span className="material-symbols-outlined fs-7">send</span>{" "}
          {t("request.send")}
        </div>
      </div>
    </div>
  );
};

export default RespondReview;
