import React, { useState, useContext, useEffect } from "react";
import { RequestContext } from "../../context/RequestContext";
import { ProviderContext } from "../../context/ProviderContext";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import { cardAnimation } from "../../utils/HomeUtils";
import ConfirmationBox from "../common/ConfirmationBox";


const ServiceSelectionForm = () => {
  const { t } = useTranslation();
  const { allServices } = useContext(RequestContext);
  const { provider, providerProfile, updateProviderData } = useContext(ProviderContext);

  const [selectedServices, setSelectedServices] = useState([]);
  const [initialSelectedServices, setInitialSelectedServices] = useState([]);
  const [visibleCategories, setVisibleCategories] = useState({});
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  useEffect(() => {
    if (providerProfile && providerProfile.services) {
      const initialSelectedServices = providerProfile.services.map(
        (s) => s.service_id
      );
      setSelectedServices(initialSelectedServices);
      setInitialSelectedServices(initialSelectedServices); 
    }
  }, [providerProfile]);

  const servicesByCategory = allServices.reduce((acc, service) => {
    const { category } = service;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(service);
    return acc;
  }, {});

  // Function to count selected services in each category
  const countSelectedServicesInCategory = (category) => {
    const categoryServices = servicesByCategory[category].map(
      (service) => service.id
    );
    return selectedServices.filter((id) => categoryServices.includes(id))
      .length;
  };

  const handleServiceChange = (service) => {
    setSelectedServices((prev) => {
      const isServiceSelected = prev.includes(service.id);
      if (isServiceSelected) {
        return prev.filter((id) => id !== service.id);
      } else {
        return [...prev, service.id];
      }
    });
  };

  const toggleVisibility = (category, event) => {
    event.preventDefault();
    setVisibleCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const handleToggleCategory = (category, event) => {
    event.preventDefault();
    const categoryServices = servicesByCategory[category];
    const categoryServiceIds = categoryServices.map((service) => service.id);
    const areAllSelected = categoryServiceIds.every((id) =>
      selectedServices.includes(id)
    );

    if (areAllSelected) {
      setSelectedServices((prev) =>
        prev.filter((id) => !categoryServiceIds.includes(id))
      );
    } else {
      setSelectedServices((prev) => [
        ...new Set([...prev, ...categoryServiceIds]),
      ]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConfirmationVisible(true);
    setConfirmationMessage("home_provider.saving");
    try {
      await updateProviderData(providerProfile._id, provider.token, {
        services: selectedServices.map((id) => ({ service_id: id })),
      });
      setConfirmationMessage(t("home_provider.done")); // Assuming you have a translation key for "saved"
      setTimeout(() => {
        setConfirmationVisible(false);
      }, 5000); // Hide confirmation box after 5 seconds
    } catch (error) {
      console.error("Failed to update provider services:", error);
      setConfirmationMessage("Error saving areas. Please try again.");
      setTimeout(() => {
        setConfirmationVisible(false);
      }, 8000);
    }
  };

    // Function to check if there are changes
    const hasChanges = () => {
      const currentServicesSet = new Set(selectedServices);
      const initialServicesSet = new Set(initialSelectedServices);
      if (currentServicesSet.size !== initialServicesSet.size) return true;
      for (let id of currentServicesSet) {
        if (!initialServicesSet.has(id)) return true;
      }
      return false;
    };

  // Animation variants for framer-motion 
  const containerVariants = { hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.1, }, }, };

  return (
    <motion.div
      className="card card-flush"
      initial={cardAnimation.initial}
      animate={cardAnimation.animate}
      transition={cardAnimation.transition}
    >
      <div className="card-header">
        <h3 className="card-title">{t("home_provider.select_service")}</h3>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          {Object.keys(servicesByCategory).map((category, index) => (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              key={category}
              className="mb-3"
            >
              <span
                className="btn btn btn-outline border border-warning d-inline-flex w-100 align-items-center justify-content-between"
                onClick={(e) => toggleVisibility(category, e)}
              >
                <span className="text-start d-block">
                  {category}{" "}
                  {countSelectedServicesInCategory(category) > 0 && (
                    <span className="badge badge-warning mx-2">
                      {countSelectedServicesInCategory(category)}
                    </span>
                  )}
                </span>
                <span
                  className={`bi ${
                    visibleCategories[category]
                      ? "bi-chevron-up"
                      : "bi-chevron-down"
                  }`}
                ></span>
              </span>
              <AnimatePresence>
                {visibleCategories[category] && (
                  <motion.div
                    className="mt-2"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                  >
                    {servicesByCategory[category].map((service, index) => (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        key={service._id}
                        className="form-check my-2"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={selectedServices.includes(service.id)}
                          onChange={() => handleServiceChange(service)}
                          id={`service-${service._id}`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`service-${service._id}`}
                        >
                          {service.label}
                        </label>
                      </motion.div>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
          {hasChanges() && (
          <motion.button
            whileHover={{
              scale: 0.98,
              rotate: "2deg",
            }}
            whileTap={{
              scale: 0.94,
              rotate: "4deg",
            }}
            type="submit"
            className="btn btn-mc w-100 my-6"
          >
            {t("home_provider.save")}
          </motion.button>)}
        </form>
      </div>

      {confirmationVisible && (
        <ConfirmationBox
          icon="done_outline"
          message={confirmationMessage}
          bgColor="rgba(0,0,0,0.5)"
          className="bg-success text-white"
          outTime={5000}
        />
      )}
    </motion.div>
  );
};

export default ServiceSelectionForm;
