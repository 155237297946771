import React, { useState, useContext } from "react";
import { TextField, Button, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MasterContext } from "../../context/MasterContext";
import { RequestContext } from "../../context/RequestContext";

const LoginMaster = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const { t } = useTranslation();
  
    const { login } = useContext(MasterContext);
    const { fetchAllServices } = useContext(RequestContext); // Assuming fetchAllServices is the correct method name
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError(null); // Reset error state before attempting login
  
      try {
        await login(email, password); // Use the login method provided by MasterContext
        fetchAllServices(); // Initialize or refresh services upon successful login
      } catch (error) {
        setError(error.message || t("login.error")); // Use a translation key for the error message
      }
    };

    return (
        <div
            className="app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat"
            style={{ backgroundImage: "url('./images/bg4.jpg')", height: '100vh' }}
        >
            <div className="d-flex flex-column flex-column-fluid flex-lg-row">
                <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
                    <div className="d-flex flex-center flex-lg-start flex-column">
                        <img
                            src="./images/logo/maestroClave-long.png"
                            alt="logo"
                            style={{ maxWidth: 300 }}
                        />
                        <h2 className="text-white fw-normal m-0">Master Access</h2>
                    </div>
                </div>
                <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20">
                    <div className="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
                        <h3 className="text-center mb-6">Master Login</h3>
                        <form className="form w-100" onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                type="email"
                                autoComplete="email"
                                value={email}
                                label={t("profile.email")}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <br /><br />
                            <TextField
                                fullWidth
                                type="password"
                                autoComplete="current-password"
                                value={password}
                                label={t("profile.password")}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <br /><br />
                            <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                className="maestro-orange-button"
                            >
                                {t("login.button")} &nbsp; <span className="material-symbols-outlined">person_check</span>
                            </Button>
                            {error && <Alert severity="error" style={{ marginTop: '20px' }}>{error}</Alert>}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginMaster;