import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const UserChangePassword = ({ setLayout, newPassword = () => {} }) => {
  const [password, setPassword] = useState({
    previous: "",
    newPassword: "",
    verifyPassword: "",
  });
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    if (password.newPassword !== password.verifyPassword) {
      setError(t("profile.no_match"));
    } else if (password.newPassword.length < 5) {
      setError(t("profile.not_strong"));
    } else if (password.previous.length < 5) {
      setError(t("profile.incorrect_password"));
    } else {
      const response = await newPassword(
        password.previous,
        password.newPassword
      );
      //send passwords to API
      if (response.success) {
        setSuccess(true);
      } else {
        console.log(response);
        setError(response.data);
      }
    }
  };
  return (
    <form>
      {!success && (
        <>
          <TextField
            fullWidth
            type="password"
            autoComplete="current-password"
            value={password.previous}
            label={t("profile.old_password")}
            className="my-2"
            onChange={(e) =>
              setPassword({ ...password, previous: e.target.value })
            }
          />
          <TextField
            fullWidth
            type="password"
            autoComplete="current-password"
            value={password.NewPassword}
            label={t("profile.new_password")}
            className="my-2"
            onChange={(e) =>
              setPassword({ ...password, newPassword: e.target.value })
            }
          />
          <TextField
            fullWidth
            type="password"
            autoComplete="current-password"
            value={password.verifyPassword}
            label={t("profile.confirm_password")}
            className="my-2"
            onChange={(e) =>
              setPassword({ ...password, verifyPassword: e.target.value })
            }
          />{" "}
        </>
      )}

      {error && <div className="error py-4">{error}</div>}
      {!success ? (
        <div className="how-it-works-options">
          <span
            className="btn btn-light btn-sm w-100 d-flex align-items-center justify-content-center mx-1"
            onClick={() => setLayout("info")}
          >
            {t("profile.cancel")}
          </span>
          <span
            className="btn btn-mc btn-sm w-100 mx-1"
            onClick={handleSubmit}
          >
            {t("profile.change_password")}
          </span>
        </div>
      ) : (
        <>
          <div className=" text-mc btn btn-outline text-center w-100">
            {t("profile.password_changed")}
          </div>
          <span
            className="btn btn-mc btn-sm w-100 mx-1 d-block my-2"
            onClick={() => setLayout("info")}
          >
            {t("profile.go_back")}
          </span>
        </>
      )}
    </form>
  );
};

export default UserChangePassword;
