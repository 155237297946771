import React from "react";

const ClientRow = ({ user, setCurrentClient = () => {} }) => {
  return (
    <>
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <span className="text-gray-900 fw-bold text-hover-primary fs-6">
                {user?.first_name}
              </span>

              <span className="text-muted fw-semibold text-muted d-block fs-7">
                {user?.last_name}
              </span>
            </div>
          </div>
        </td>
        <td>
          <span className="text-gray-900 fw-bold text-hover-primary d-block fs-6">
            {user?.email}
          </span>
          <span className="text-muted fw-semibold text-muted d-block fs-7">
            {user?.phone}
          </span>
        </td>
        <td>
          <span className="text-gray-900 fw-bold text-hover-primary d-block fs-6">
            {user?.secondary_email}
          </span>
          <span className="text-muted fw-semibold text-muted d-block fs-7">
            {user?.secondary_phone}
          </span>
        </td>
        <td>
          <span className="text-gray-900 fw-bold text-hover-primary d-block fs-6">
            {user?.formatted_address}
          </span>
          <span className="text-muted fw-semibold text-muted d-block fs-7">
            {user?.unit}
          </span>
        </td>
        <td>
          <div className="d-flex flex-row">
            <span
              onClick={() => setCurrentClient(user)}
              className="btn btn-sm btn-icon btn-light-primary material-symbols-outlined"
            >
              tune
            </span>
          </div>
        </td>
      </tr>
    </>
  );
};

export default ClientRow;
