import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';


const ItemPricingData = ({ data, index, color }) => {
    // It's good practice to destructure your props for cleaner code
    const { icon, label, data: itemData, price } = data;
    const itemVariants = {
        hidden: { opacity: 0, x: -10 }, // Initial state of the item
        visible: i => ({
            opacity: 1,
            x: 0,
            transition: {
                delay: i * 0.2, // Delay each item based on its index
            },
        }),
    };

    return (
        <AnimatePresence>
            <motion.div
                variants={itemVariants}
                initial="hidden"
                animate="visible"
                custom={index} // Pass the index as a custom prop to use in the variant
            >
                {itemData && (
                    <div className='d-flex flex-stack mb-2 align-items-center bg-hover-light rounded'>

                        <div className='d-flex flex-row'>
                            <span className={`material-symbols-outlined btn btn-icon btn-hover-${color} btn-hover- me-4 mc-color-2 mc-bg-color-3`} style={{ minWidth: 40 }}>
                                {icon}
                            </span>
                            <div className='d-flex flex-column'>
                                <span className='fs-7 fw-bold'>{itemData}</span>
                                <small className='text-muted fw-bold'>{label}</small>
                            </div>
                        </div>
                        {price && <span className='btn btn-secondary disabled btn-sm'>{price}</span>}
                    </div>
                )}
            </motion.div>
        </AnimatePresence>
    );
}

export default ItemPricingData;