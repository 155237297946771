import React from "react";
import UserInfoDetail from "./UserInfoDetail";
import { useTranslation } from "react-i18next";

const UserInfo = ({ user, setLayout}) => {
  const {t} = useTranslation();
  return (
    <div className="flex flex-column pb-8 ">
      <UserInfoDetail
        label={t("profile.email")}
        data={user?.email}
        verifiable={true}
        verified={user?.email_verified}
      />
      <UserInfoDetail
        label={t("profile.ID")}
        data={user?.id}
        verifiable={true}
        verified={false}
      />
      { user?.company && <UserInfoDetail
        label={t("profile.company")}
        data={user?.company}
        verifiable={false}
        verified={false}
      /> }
      <UserInfoDetail
        label={t("profile.name")}
        data={user?.first_name}
        verifiable={false}
        verified={false}
      />
      <UserInfoDetail
        label={t("profile.last_name")}
        data={user?.last_name}
        verifiable={false}
        verified={false}
      />
      <UserInfoDetail
        label={t("profile.address")}
        data={user?.formatted_address}
        verifiable={false}
        verified={false}
      />
      <UserInfoDetail
        label={t("profile.unit")}
        data={user?.unit}
        verifiable={false}
        verified={false}
      />
      <UserInfoDetail
        label={t("profile.phone")}
        data={user?.phone}
        verifiable={true}
        verified={user?.phone_verified}
      />
      <UserInfoDetail
        label={t("profile.phone_2")}
        data={user?.second_phone}
        verifiable={true}
        verified={user?.second_phone_verified}
      />
      
      <div className="how-it-works-options" >

      <span
        
        className="btn btn-sm btn-mc d-flex justify-content-center align-items-center w-100 mx-1 my-2"
        onClick={() => setLayout("edit")}
        >
        {t("profile.edit")}
      </span>
      <span
        
        className="btn btn-sm btn-mc w-100  mx-1 my-2"
        onClick={() => setLayout("password")}
        >
        {t("profile.change_password")}
      </span>
        </div>
    </div>
  );
};

export default UserInfo;
