import React, { createContext, useEffect, useState } from 'react';


export const CurrentContext = createContext()

const CurrentContextProvider = (props) => {
    const [isClient, setIsClient] = useState(true);

    useEffect(()=>{
        const myStatus = JSON.parse(localStorage.getItem("client"))
        if(myStatus !== undefined && myStatus !== null){
            setIsClient(myStatus)
        }
    },[])
    
    const changeIsClient = (value) =>{
        setIsClient(value)
        localStorage.setItem("client",value)
    }

    return ( 
        <CurrentContext.Provider value={{isClient, changeIsClient}}>
            {props.children}
        </CurrentContext.Provider>
     );
}
 
export default CurrentContextProvider;