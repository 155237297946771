import React, { useContext , useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ExpectedDate } from '../../../data/dummyData';
import { RequestContext } from '../../../context/RequestContext';
import { useTranslation } from 'react-i18next';

const SearchFormTop = () => {
  const {t} = useTranslation()
  const expectedDates= ExpectedDate()
  const {expectedDate, setContextExpectedDate,removeAddressUnit, setContextExpiryDate} = useContext(RequestContext)
  const [exp, setExp] = useState(expectedDate)
  const [expiryDate, setExpiryDate]= useState(null)

  const  handleExpectedDate = (value)=>{
    if(value != null){
      setExp(value)
      const expiry = new Date() 
      if(value.id === 1){
        setExpiryDate(expiry.setDate(expiry.getDate()+2))
      } else if(value.id === 2){
        setExpiryDate(expiry.setDate(expiry.getDate()+14))
      } else {
        setExpiryDate(expiry.setDate(expiry.getDate()+365))
      }
      setContextExpiryDate(expiryDate)
      setContextExpectedDate(value)
    } else {
      removeAddressUnit()
    }
  }


    return ( 
        <Autocomplete
        size={'large'}
        disablePortal
        id="expectedDate"
        options={expectedDates}
        sx={{ width: '100%' }}
        value={exp }
        className="search-form-top"
        onChange={(e, value)=> handleExpectedDate(value)} 
        renderInput={(params) => <TextField {...params} label={t("request.what_deadline")} />}
        />
      );
}
 
export default SearchFormTop;