import React, { useState } from "react";
import AddServiceProvider from "../../components/Services/AddServiceProvider";
import ServiceNav from "../../components/Services/ServiceNav";
import { useTranslation } from "react-i18next";
import ProviderSummary from "../../components/Services/ProviderSummary";
import AddArea from "../../components/Services/AddArea";
import MyPlan from "../../components/Services/MyPlan";
import AboutBusiness from "../../components/Services/AboutBusiness";
import HeaderProvider from "../../components/common/HeaderProvider";
import { motion } from 'framer-motion'; 



const HomeProvidersLogged = () => {
  const { t } = useTranslation();
  const [mainLayout, setMainLayout] = useState(0);

  const selectMainLayout = (value) => {
    setMainLayout(value);
  };

  const steps = [
    {
      title: t("home_provider.provider_summary"),
      description: t("home_provider.provider_summary_desc"),
      icon: "folder_supervised"
    },
    {
      title: t("home_provider.add_services"),
      description: t("home_provider.add_services_desc"),
      icon: "handyman"
    },
    {
      title: t("home_provider.add_area"),
      description: t("home_provider.add_area_desc"),
      icon: "where_to_vote"
    },
    {
      title: t("home_provider.my_plan"),
      description: t("home_provider.my_plan_desc"),
      icon: "groups"

    },
    {
      title: t("home_provider.about_my_business"),
      description: t("home_provider.about_my_business_desc"),
      icon: "settings_account_box"
    },
  ];

   // Animation variants for the card
   const cardVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delay: 0.3, duration: 0.5 } },
  };

  // Animation variants for ServiceNav components
  const listVariants = {
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.1,
      },
    },
    hidden: { opacity: 0 },
  };

  // Animation variant for individual ServiceNav item
  const itemVariants = {
    hidden: { x: -10, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  return (
    <HeaderProvider>
        <div className="col-lg-4">
          <motion.div
            className="stepper stepper-pills stepper-column d-flex flex-column  gap-10"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
          >
            <div className="card d-flex justify-content-center justify-content-xl-start w-100 mb-3">
              <div className="card-body px-6 px-lg-10 px-xxl-15 py-20">
                <motion.div className="stepper-nav" variants={listVariants} initial="hidden" animate="visible">
                  {steps.map((item, index) => (
                    <motion.div key={index} variants={itemVariants}>
                      <ServiceNav
                        current={mainLayout}
                        index={index}
                        title={item.title}
                        description={item.description}
                        action={selectMainLayout}
                        icon={item.icon}
                      />
                    </motion.div>
                  ))}
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
        <div className="col-lg-8">
          {mainLayout === 0 && <ProviderSummary />}
          {mainLayout === 1 && <AddServiceProvider />}
          {mainLayout === 2 && <AddArea />}
          {mainLayout === 3 && <MyPlan />}
          {mainLayout === 4 && <AboutBusiness />}
        </div>
    </HeaderProvider>
  );
};

export default HomeProvidersLogged;
