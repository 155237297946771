import React, { useContext, useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { AuthContext } from '../../context/AuthContext';

const BottomNav = () => {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const location = useLocation(); // Use location to get the current path
    const { user } = useContext(AuthContext);

    const values = ["/", "/request", "/userprojects", "/userprofile", "/signup", "/login"];

    useEffect(() => {
        const currentPath = location.pathname; // Use location.pathname instead of window.location.pathname
        const newValue = values.indexOf(currentPath);
        setValue(newValue !== -1 ? newValue : 0); // Fallback to the first value if path is not found
    }, [location]); // Depend on location to re-evaluate when path changes

    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate(values[newValue]);
    };

    return (
        <Paper
            sx={{ position: "fixed", bottom: 7, left: `2.5%`, right: 0, width: `95%` }}
            elevation={3}
            className="bottom-nav-wrap"
        >
            <BottomNavigation
                showLabels
                value={value}
                onChange={handleChange}
            >
                <BottomNavigationAction
                    label="Home"
                    icon={<span className="material-symbols-outlined">cottage</span>}
                />
                <BottomNavigationAction
                    label="Buscar"
                    icon={<span className="material-symbols-outlined">search</span>}
                />
                {user ? (
                    <BottomNavigationAction
                        label="Proyectos"
                        icon={<span className="material-symbols-outlined">assignment</span>}
                    />
                ) : (
                    <BottomNavigationAction
                        label="Registrate"
                        icon={<span className="material-symbols-outlined">how_to_reg</span>}
                    />
                )}
                {user ? (
                    <BottomNavigationAction
                        label="Perfil"
                        icon={<span className="material-symbols-outlined">account_circle</span>}
                    />
                ) : (
                    <BottomNavigationAction
                        label="Login"
                        icon={<span className="material-symbols-outlined">login</span>}
                    />
                )}
            </BottomNavigation>
        </Paper>
    );
};

export default BottomNav;
