import React from "react";
import { motion } from "framer-motion";

const CardListItem = ({ item, index, bkg }) => {
  const { title, description, icon } = item;
  return (
    <div className="col-lg-6">
      <motion.div
      whileHover={{
        scale:0.95,
        rotate: '1deg'
      }}
        className="card-list-item card card-bordered"
        style={{ background: bkg, opacity: 1 - index / 20 }}
      >
        <span className="material-symbols-outlined">{icon}</span>

        <div>
          <h5>{title}</h5>
          <p className="text-muted">{description}</p>
        </div>
      </motion.div>
    </div>
  );
};

export default CardListItem;
