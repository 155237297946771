import React, { useContext, useState, useEffect } from "react";
import { ProjectContext } from "../../context/ProjectContext";
import { AuthContext } from "../../context/AuthContext";
import RequestCard from "../../components/Request/RequestCard";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import NoProjects from "../../components/Request/NoProjects";
import HeaderClient from "../../components/common/HeaderClient";
import { motion } from 'framer-motion';

const RequestOptions = () => {
  const [providerArray, setProviderArray] = useState([]);
  const [providerStatus, setProviderStatus] = useState([]);
  const [loading, setLoading] = useState(true);

  const { currentProject, retrieveProjectOptions } = useContext(ProjectContext);
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {

    fetchProviderOptions();
  }, [user, currentProject, retrieveProjectOptions]); 

  const fetchProviderOptions = async () => {
    if (user?.token && currentProject) {
      const options = await retrieveProjectOptions(currentProject, user.token);
      if (options && options.providers && options.providers.length > 0) {
        setProviderArray(options?.providers || []);
        setProviderStatus(options?.providerStatus || []);
      }
      setLoading(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 }
  };

  return (
    <HeaderClient>
      <h2 className="text-white mb-8">{t("request.almost_there")}</h2>
      <div className="container mb-8">
        {loading && (
          <div className="col-lg-6">
          <div className="card card-flush">
            <div className="card-header">
              <div className="card-title">
                <Skeleton count={1} />
              </div>
            </div>
            <div className="card-body">
              <Skeleton count={3} />
            </div>
          </div>
          </div>
        )}
        {!loading && providerArray && providerArray.length > 0 && (
          <motion.div
            className="row g-4"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {providerArray.map((provider, index) => (
              <motion.div className="col-lg-6" key={index} variants={itemVariants}>
                <RequestCard
                  provider={provider}
                  providerStatus={providerStatus}
                  project_id={currentProject}
                  fetchProviderOptions={fetchProviderOptions}
                />
              </motion.div>
            ))}
          </motion.div>
        )}
        {!loading && providerArray && providerArray.length === 0 && (
          <motion.div variants={itemVariants} initial="hidden" animate="visible">
            <NoProjects
              icon="sentiment_neutral"
              title={t("request.no_options")}
              description={t("request.no_options_desc")}
            />
          </motion.div>
        )}
      </div>
    </HeaderClient>
  );
}

export default RequestOptions;
