import React, { useState, useRef, useContext, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import { TextField, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProviderContext } from "../../context/ProviderContext";
import { motion } from "framer-motion";
import ConfirmationBox from "../common/ConfirmationBox";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const options = {
  types: ["(cities)"],
  componentRestrictions: { country: "AR" },
};

const center = {
  lat: -34.603722,
  lng: -58.381592,
};

const AddArea = () => {
  const { t } = useTranslation();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Replace with your actual API key
    libraries,
  });

  const [markers, setMarkers] = useState([]);
  const [originalMarkers, setOriginalMarkers] = useState([]); // Track the original state of markers
  const [address, setAddress] = useState("");
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const { provider, providerProfile, updateProviderData } =
    useContext(ProviderContext);

  useEffect(() => {
    if (
      isLoaded &&
      providerProfile &&
      Array.isArray(providerProfile.locations)
    ) {
      const initialMarkers = providerProfile.locations.map((location) => ({
        lat: location.coordinates[1],
        lng: location.coordinates[0],
        formatted_address: location.formatted_address,
      }));
      setMarkers(initialMarkers);
      setOriginalMarkers(initialMarkers);
    }
  }, [providerProfile, isLoaded]);

  useEffect(() => {
    if (isLoaded && mapRef.current && markers.length) {
      updateMapBounds(markers);
    }
  }, [markers, mapRef, isLoaded]);

  const updateMapBounds = (markers) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((marker) => {
      bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
    });
    mapRef.current.fitBounds(bounds);
  };

  const handleSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (!place || !place.geometry) {
      console.error("No details available for the input: ", place);
      return; // Exit the function if no place or geometry is found
    }
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const formatted_address = place.formatted_address;

    const newMarker = {
      lat,
      lng,
      formatted_address,
    };
    setMarkers((current) => [...current, newMarker]);
    setAddress(""); // Reset the Autocomplete field after selection
    setFeedbackMessage(`Location added: ${place.formatted_address}.`);
  };

  const handleCloseFeedback = () => {
    setFeedbackMessage("");
  };

  const handleRemovePlace = (indexToRemove) => {
    setMarkers((current) =>
      current.filter((_, index) => index !== indexToRemove)
    );
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const handleSubmit = async () => {
    setConfirmationVisible(true);
    setConfirmationMessage("home_provider.saving");
    const formattedLocations = markers.map((marker) => ({
      type: "Point",
      coordinates: [marker.lng, marker.lat],
      formatted_address: marker.formatted_address,
    }));

    try {
      await updateProviderData(providerProfile._id, provider.token, {
        locations: formattedLocations,
      });
      setConfirmationMessage(t("home_provider.done")); // Assuming you have a translation key for "saved"
      setTimeout(() => {
        setConfirmationVisible(false);
      }, 5000); // Hide confirmation box after 5 seconds
      console.log("Areas successfully saved!");
    } catch (error) {
      console.error("Failed to update provider areas:", error);
      setConfirmationMessage("Error saving areas. Please try again.");
      setTimeout(() => {
        setConfirmationVisible(false);
      }, 8000);
    }
    setOriginalMarkers(markers); // Update originalMarkers state after successful save
  };

  const hasChanges = () => {
    return JSON.stringify(markers) !== JSON.stringify(originalMarkers);
  };

  return (
    <motion.div
      initial={{ scale: 0.95, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="card card-flush"
    >
      <div className="card-header">
        <h3 className="card-title">{t("home_provider.add_area")}</h3>
      </div>
      <div className="card-body">
        <Autocomplete
          onLoad={(ref) => (autocompleteRef.current = ref)}
          onPlaceChanged={handleSelect}
          options={options}
        >
          <TextField
            fullWidth
            label={t("home_provider.type_a_city")}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="mb-6"
          />
        </Autocomplete>
        <GoogleMap
          id="map"
          mapContainerStyle={mapContainerStyle}
          zoom={8}
          center={center}
          onLoad={(map) => (mapRef.current = map)}
        >
          {markers.map((marker, index) => (
            <Marker
              key={index}
              position={{ lat: marker.lat, lng: marker.lng }}
            />
          ))}
        </GoogleMap>
        {markers.map((marker, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: index * 0.4 }}
            className="d-flex flex-row justify-content-between mt-6 align-items-center"
          >
            <div className="btn btn-sm btn-light w-75">
              {marker.formatted_address}
            </div>
            <span
              className="material-symbols-outlined btn btn-danger btn-icon btn-sm"
              onClick={() => handleRemovePlace(index)}
            >
              delete
            </span>
          </motion.div>
        ))}
        {hasChanges() && (<motion.span
          whileHover={{
            scale: 0.98,
            rotate: "2deg",
          }}
          whileTap={{
            scale: 0.94,
            rotate: "4deg",
          }}
          className="btn btn-mc btn-sm w-100 my-6"
          onClick={handleSubmit}
        >
          {t("home_provider.save_areas")}
        </motion.span>)}
      </div>
      {confirmationVisible && (
        <ConfirmationBox
          icon="done_outline"
          message={confirmationMessage}
          bgColor="rgba(0,0,0,0.5)"
          className="bg-success text-white"
          outTime={5000}
        />
      )}

    </motion.div>
  );
};

export default AddArea;
