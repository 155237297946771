import React, {createContext, useState} from 'react';

export const WorkoutContext = createContext()


const WorkoutContextProvider = (props) => {

    const [workouts, setWorkouts] = useState([])

    const addWorkout = (workout) => {
        setWorkouts([workout, ...workouts])
    }

    const setAllWorkouts = (workouts) => {
        setWorkouts(workouts)
    }

    const deleteWorkout = (id) => {
        setWorkouts(workouts.filter((workout)=>workout._id !== id))
    }

    return ( 
        <WorkoutContext.Provider value={{workouts, addWorkout, setAllWorkouts, deleteWorkout}}>
            {props.children}
        </WorkoutContext.Provider>
     );
}
 
export default WorkoutContextProvider;