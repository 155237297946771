import React from 'react';
import RequestProviderCardItem from './RequestProviderCardItem';


const TabSummary = ({ data, color}) => {
    
  
    return ( <div>
{data.length > 0 &&
            data.map((item, index) => (
              <RequestProviderCardItem
                key={`${item.label}-${index}`}
                data={item}
                index={index}
                color={color}
              />
            ))}
    </div> );
}
 
export default TabSummary;