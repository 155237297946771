import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {motion} from 'framer-motion'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UserCallToAction = () => {
  const { user } = useContext(AuthContext);
  const {t} = useTranslation()
  return (
    <div className="user-cta">
      {user ? (
        <Link to="/request">
          <motion.span
            whileHover={{scale: 0.98, rotate: '0.2deg'}}
            whileTap={{scale: 0.95, rotate: '0.4deg'}}
            className="btn btn-lg btn-mc text-hover-white d-block"
          >
            {t("request.request_estimate")} &nbsp;{" "}
            <span className="material-symbols-outlined fs-5">assignment_turned_in</span>
          </motion.span>
        </Link>
      ) : (
        <Link to="/signup">
          <motion.span
            whileHover={{scale: 0.98, rotate: '0.2deg'}}
            whileTap={{scale: 0.95, rotate: '0.4deg'}}
            className="btn btn-lg btn-mc text-hover-white d-block"
          >
            {t("nav.signup")} &nbsp;{" "}
            <span className="material-symbols-outlined">person_add</span>
          </motion.span>
        </Link>
      )}
    </div>
  );
};

export default UserCallToAction;
