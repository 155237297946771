import React from "react";
import NavbarProvider from "./NavbarProvider";
import Footer from "./Footer";

const HeaderProvider = ({ children, title }) => {
  return (
    <>
      <div className="bg-gray-900 mh-75vh">
        <div className="inner-pages-title ">
          <NavbarProvider color="white" />
        </div>
        <div className="outer-wrap">
          <div className="boxed">
            <div className="home-form pb-100 mh-750vh">
              <h2 className="text-white mb-8">{title}</h2>
              <div className="row mb-8 mt--100 g-4">{children}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HeaderProvider;
