export const HowItWorksPro = [
  {
    title: "Únete",
    id: 1,
    description: "Regístrate y muestra tu experiencia.",
    icon: "app_registration",
  },
  {
    title: "Recibe Proyectos",
    id: 2,
    description: "Te emparejamos con proyectos acordes a tu perfil.",
    icon: "group_add",
  },
  {
    title: "Envía Propuestas",
    id: 3,
    description: "Presenta tus mejores ofertas a los clientes.",
    icon: "request_page",
  },
  {
    title: "Trabaja y Crece",
    id: 4,
    description:
      "Realiza el servicio y recibe valoraciones para impulsar tu negocio.",
    icon: "reviews",
  },
];

//states for animations via framer-motion
export const initial = {
  y: 30,
  opacity: 0,
  scale: 0.95,
};
export const animate = {
  y: 0,
  opacity: 1,
  scale: 1,
};

export const transition = {
  delay: 1.5,
  duration: 1,
  ease: "easeInOut",
};


export const pageVariants = {
    initial: { opacity: 0, scale: 0.98 },
    animate: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 1.02 },
  }


export const cardAnimation = {
    initial: { scale: 0.95, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    transition: { duration: 0.5 },
  };