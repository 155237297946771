import React, { createContext, useState, useCallback } from "react";

export const MasterContext = createContext();

const MasterContextProvider = ({ children }) => {
  const [master, setMaster] = useState(() => JSON.parse(localStorage.getItem("master")));
  const [masterProfile, setMasterProfile] = useState(() => JSON.parse(localStorage.getItem("masterProfile")));

  const login = useCallback(async (email, password) => {
    try {
      const response = await fetch("/api/master/login", {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "An error occurred during login.");
      }

      const loggedMaster = await response.json();
      setMaster(loggedMaster);
      localStorage.setItem("master", JSON.stringify(loggedMaster));
    } catch (error) {
      console.error("Login error:", error.message);
      throw error; // Rethrow to let the calling component handle it
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("master");
    setMaster(null);
    localStorage.removeItem("masterProfile");
    setMasterProfile(null);
  }, []);

  const signup = useCallback(async (email, password) => {
    // Implement the signup logic here
    // This example assumes the signup API endpoint is similar to login but might differ
    console.log("Signup logic to be implemented");
    // After successful signup, you might want to automatically log the user in
    // login(email, password);
  }, [login]);

  const setMasterProfileData = useCallback((profileData) => {
    setMasterProfile(profileData);
    localStorage.setItem("masterProfile", JSON.stringify(profileData));
  }, []);

  const newPassword = useCallback(async (oldPassword, newPassword) => {
    if (!master || !master.token) {
      console.error("Authentication required");
      return { success: false, data: "Authentication required" };
    }

    try {
      const response = await fetch(`/api/master/pwd/${master._id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${master.token}`,
        },
        body: JSON.stringify({ old_password: oldPassword, new_password: newPassword }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "An error occurred while changing the password.");
      }
      if (response.status === 401) {
        logout()
      }

      return { success: true };
    } catch (error) {
      console.error("Password change error:", error.message);
      return { success: false, data: error.message };
    }
  }, [master]);

  return (
    <MasterContext.Provider
      value={{
        master,
        login,
        logout,
        signup,
        masterProfile,
        setMasterProfile: setMasterProfileData,
        newPassword,
      }}
    >
      {children}
    </MasterContext.Provider>
  );
};

export default MasterContextProvider;