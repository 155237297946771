import React, { useEffect, useState } from 'react';
import { getEstimate, updateEstimate } from '../../api/api';
import EditEstimate from './EstimateEdit';
import EstimateView from './EstimateView';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const TabEstimateUser = ({ token, userRequestId, color , setSelectedTab}) => {
    const [estimate, setEstimate] = useState(null)
    const [loading,setLoading]=useState(true)
    const {t} = useTranslation()

    const itemVariants = {
        hidden: { opacity: 0, x: -10 }, // Initial state of the item
        visible: i => ({
          opacity: 1,
          x: 0,
          transition: {
            delay: i * 0.2, // Delay each item based on its index
          },
        }),
      };

    const handleAccept = async ()=>{
      const accepted = new Date()
      await updateEstimate( {accepted} ,estimate._id,token)
      getCurrentEstimate()
    }

    const getCurrentEstimate = async () => {
        const myEstimate = await getEstimate({ userRequestId }, token)
        if (myEstimate && myEstimate._id) {
            setEstimate(myEstimate)
        }
        setLoading(false)
    }
    useEffect(() => {
        getCurrentEstimate()
    }, [userRequestId])

    return (<div className=' pt-2'>
        {loading && <motion.small
      variants={itemVariants}
      initial="hidden"
      animate="visible"
      
      className='btn btn-light d-block btn-sm '>{t("request.loading")}</motion.small>}
        {!loading && !estimate && <motion.span 
        variants={itemVariants}
        initial="hidden"
        animate="visible"
        className='btn btn-light disabled btn-sm'
        
        >{t("estimate.no_estimate_yet")}</motion.span>}
        {!loading  && estimate && <EstimateView setSelectedTab={setSelectedTab} estimate={estimate} color={color} />}
        {!loading  && estimate && !estimate.accepted && <div onClick={()=>{handleAccept()}} className='d-flex justify-content-end my-3'><motion.span 
        variants={itemVariants}
        initial="hidden"
        animate="visible"
        className='btn btn-mc btn-sm'
        
        >{t("estimate.accept")}</motion.span></div>}
    </div>);
}

export default TabEstimateUser;