import React from "react";
import { Parallax } from "react-scroll-parallax";
import { motion,  } from "framer-motion";
import MotionDiv from "../../components/common/MotionDiv";

const HomeTitle = ({ accent_top_one, accent_top_two, title, subtitle }) => {
  return (
    <div className="home-header banner">
      <div className="home-title-vertical-wrap">
        <Parallax speed={-18}>
          <motion.span
            initial={{
              opacity: 0,
              x: "-100px",
              transform: "-180deg",
            }}
            animate={{
              opacity: 1,
              x: 0,
              transform: "-180deg",
            }}
            transition={{
              duration: 1.5,
              ease: "easeInOut",
            }}
            className="home-title-vertical"
          >
            {accent_top_one}
          </motion.span>
        </Parallax>

        <Parallax speed={-14}>
          <motion.span
            initial={{
              opacity: 0,
              x: "-100px",
              transform: "-180deg",
            }}
            animate={{
              opacity: 1,
              x: 0,
              transform: "-180deg",
            }}
            transition={{
              delay: 0.7,
              duration: 2,
              ease: "easeInOut",
            }}
            className="home-title-vertical-two"
          >
            {accent_top_two}
          </motion.span>
        </Parallax>
      </div>
      <MotionDiv className="blurry-box p-8 border-radius">
        <h3 className="text-white">{title}</h3>
        <p className="text-light-primary">{subtitle}</p>
      </MotionDiv>
    </div>
  );
};

export default HomeTitle;
