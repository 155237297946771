import React from 'react';
import MessageItem from './MessageItem';
import ScrollableFeed from 'react-scrollable-feed';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { enUS, es, ptBR } from "date-fns/locale";
import { format, isSameDay, differenceInCalendarDays } from 'date-fns';

function MessageList({ messages, myId }) {
  const { t, i18n } = useTranslation();
  const locales = { en: enUS, es: es, br: ptBR }; // Ensure the locale keys match those used in i18n
  const dateFnsLocale = locales[i18n.language] || enUS; // Default to English if locale not found

  const itemVariants = {
    hidden: { opacity: 0, x: -10 },
    visible: i => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
      },
    }),
  };

  // Function to decide whether to show the date or day of the week
  const displayDateOrDay = (messageDate, index) => {
    if (index === 0) return formatMessageDate(messageDate);

    const previousMessageDate = new Date(messages[index - 1].createdAt);
    if (!isSameDay(messageDate, previousMessageDate)) {
      return formatMessageDate(messageDate);
    }

    return null;
  };

  // Function to format the message date
  const formatMessageDate = (date) => {
    const now = new Date();
    const differenceDays = differenceInCalendarDays(now, date);
    if (differenceDays < 1) {
      return t('date.today');
    } else if (differenceDays === 1) {
      return t('date.yesterday');
    } else if (differenceDays < 7) {
      return format(date, 'EEEE', { locale: dateFnsLocale }); // Day of the week
    } else {
      return format(date, 'PPP', { locale: dateFnsLocale }); // Date if it has passed more than 6 days
    }
  };

  return (
    <div style={{ height: '210px', overflowY: 'scroll' }}>
      <ScrollableFeed>
        {!messages && (
          <motion.small
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            className="btn btn-light d-block btn-sm"
          >
            {t('request.loading')}
          </motion.small>
        )}
        {messages && messages.length === 0 && (
          <span className="btn btn-secondary btn-sm d-block disabled">
            {t('request.no_messages')}
          </span>
        )}
        {messages &&
          messages.length > 0 &&
          messages.map((message, index) => {
            const messageDate = new Date(message.createdAt);
            const dateDisplay = displayDateOrDay(messageDate, index);
            const isLastItem = index === messages.length - 1;

            return (
              <React.Fragment key={index}>
                {dateDisplay && (
                  <motion.div
                    variants={itemVariants}
                    initial="hidden"
                    animate="visible"
                    custom={index}
                    className="d-flex justify-content-center"><span className='bg-light rounded fs-8 text-muted py-1 px-3 my-3 '>{dateDisplay}</span></motion.div>
                )}
                <MessageItem
                  isLastItem={isLastItem} key={index} index={index} message={message} myId={myId}
                />
              </React.Fragment>
            );
          })}
      </ScrollableFeed>
    </div>
  );
}

export default MessageList;