import React from "react";
import { Parallax } from "react-scroll-parallax";
import { useTranslation } from "react-i18next";
import { motion,  } from "framer-motion";
import MotionDiv from '../common/MotionDiv'


const SignUpTitle = () => {
  const {t} = useTranslation()
  return (
    <div className="home-header banner">
      <div className="inner-title-vertical-wrap">
        <Parallax speed={-12}>
          <motion.span
            initial={{
              opacity: 0,
              x: "-100px",
              transform: "-180deg",
            }}
            animate={{
              opacity: 1,
              x: 0,
              transform: "-180deg",
            }}
            transition={{
              duration: 1.5,
              ease: "easeInOut",
            }} className="sign-up-title-vertical">{t('signup.signup')}</motion.span>
        </Parallax>

        <Parallax speed={-6}>
          <motion.span
            initial={{
              opacity: 0,
              x: "-100px",
              transform: "-180deg",
            }}
            animate={{
              opacity: 1,
              x: 0,
              transform: "-180deg",
            }}
            transition={{
              delay: 0.7,
              duration: 2,
              ease: "easeInOut",
            }} className="sign-up-title-vertical-two">{t('signup.now')}</motion.span>
        </Parallax>
      </div>

      <div className="country-flag">
        <Parallax speed={-6}>
          <span className="material-symbols-outlined inner-title-icon">
            person_add
          </span>
        </Parallax>
      </div>
      <MotionDiv className="blurry-box p-6 border-radius">
        <h3 className="text-white">
          {t('signup.header')}
        </h3>
        <p className="text-white">
          {t('signup.description')}
        </p>
      </MotionDiv>
    </div>
  );
};

export default SignUpTitle;
