import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import UserProjectCard from "../../components/common/UserProjectCard";
import { RequestContext } from "../../context/RequestContext";
import { ProjectContext } from "../../context/ProjectContext";
import NoProjects from "../../components/Request/NoProjects";
import { useTranslation } from "react-i18next";
import UserCallToAction from "../../components/common/UserCTA";
import HeaderClient from "../../components/common/HeaderClient";
import { motion } from 'framer-motion';
import { pageVariants } from '../../utils/HomeUtils';
import Skeleton from "react-loading-skeleton";

const cardVariants = {
  offscreen: {
    y: 50,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  }
};

const UserProjects = () => {
  const { projects, setProjects } = useContext(ProjectContext);
  const { user } = useContext(AuthContext);
  const { allServices } = useContext(RequestContext);
  const [isLoading, setIsLoading]=useState(true)

  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      setProjects();
      setIsLoading(false)
    }
  }, [user]);

  return (
    <motion.div
      key="user_projects"
      initial="offscreen"
      animate="onscreen"
      exit="offscreen"
      variants={pageVariants}
      transition={{ duration: 3 }}
    >
      <HeaderClient>
        <div className="container">
          <div className="row g-4">
          {isLoading && (
          <div className="col-lg-6">
          <div className="card card-flush">
            <div className="card-header">
              <div className="card-title">
                <Skeleton count={1} />
              </div>
            </div>
            <div className="card-body">
              <Skeleton count={3} />
            </div>
          </div>
          </div>
        )}
            {!isLoading && projects.length && (
              projects.map((project) => {
                const myService = allServices.find(
                  (value) => value.id === project.service_id
                );
                const label = myService ? myService.label : "";
                return (
                  <motion.div
                    key={project._id}
                    variants={cardVariants}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                    className="col-lg-6"
                  >
                    <UserProjectCard
                      label={label}
                      project={project}
                    />
                  </motion.div>
                );
              })
            )}
            
            {!isLoading && projects.length < 1 && (<motion.div
              variants={cardVariants}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <NoProjects
                icon="sentiment_satisfied"
                title={t("request.no_user_project")}
                description={t("request.no_user_project_desc")}
                className=""
                cta={<UserCallToAction />}
              />
              </motion.div>
            )}
          </div>
        </div>
      </HeaderClient>
    </motion.div>
  );
};

export default UserProjects;