import React, { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { RequestContext } from "../../context/RequestContext";
import LogInTitle from "../../components/LogIn/LogInTitle";
import Navbar from "../../components/common/NavbarClient";
import { Button, TextField, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {motion} from 'framer-motion'
import {pageVariants} from '../../utils/HomeUtils'
import Footer from '../../components/common/Footer'

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const {t} = useTranslation();
  const { login } = useContext(AuthContext);
  const { fetchAllServices } = useContext(RequestContext); // Assuming this is the correct method name

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset error state

    try {
      await login(email, password); // Use login method from AuthContext
      fetchAllServices(); // Refresh or initialize services upon successful login
    } catch (error) {
      setError(error.message || "An error occurred during login.");
    }
  };

  return (
    <motion.div
      key="user_login"
      initial
      animate
      exit
      variants={pageVariants}
      transition={{ duration: 3 }}
      className="bg-gray-900"
    >
      <div className="home-client-title">
        <Navbar color="white" />
        <div className="inner-wrap">
          <div className="boxed">
            <LogInTitle />
          </div>
        </div>
      </div>
      <div className="outer-wrap mt--100">
        <div className="boxed">
          <div className="card card-flush ">
            <div className="card-header">
              <h3 className="card-title">{t("nav.login")}</h3>
            </div>
            <div className="card-body">
              <form className="sign-in" onSubmit={handleSubmit}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 my-3">
                      <TextField
                        fullWidth
                        type="email"
                        autoComplete="email"
                        value={email}
                        label={t("profile.email")}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6 my-3">
                      <TextField
                        fullWidth
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        label={t("profile.password")}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-12 my-3">
                      <Button
                        fullWidth
                        variant="contained"
                        className="maestro-orange-button"
                        type="submit"
                      >
                       {t("nav.login")}  &nbsp;{" "}
                        <span className="material-symbols-outlined">
                          person_check
                        </span>
                      </Button>
                    </div>
                  </div>
                  {error && (
                    <Alert severity="error" style={{ marginTop: "20px" }}>
                      {error}
                    </Alert>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div className="p-10 mb-100">
            <p className="text-white">
            {t("login.signup_text")}
            </p>
            <Link to="/signup">
              <Button
                fullWidth
                variant="contained"
                className="maestro-orange-button"
              >
                {t("signup.signup")} &nbsp;{" "}
                <span className="material-symbols-outlined">person_add</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer/>
    </motion.div>
  );
};

export default Login;
