import React from 'react';
import IconButtonFullWidth from '../common/IconButtonFullWidth';
import formatDistancetoNow from "date-fns/formatDistanceToNow";
import { enUS, es, ptBR } from "date-fns/locale";
import { useTranslation } from "react-i18next";




const ProjectLabel = ({project, label}) => {
  const { i18n } = useTranslation();
  const locales = { en: enUS, es: es , br: ptBR}; // Add more locales as needed
  const dateFnsLocale = locales[i18n.language] || enUS; // Default to English if locale not found

    return ( <>
    <div className='border border-dashed border-gray-300 rounded px-7 py-3 mb-6'>
        <h5>{label}</h5>
        <IconButtonFullWidth
            label={project.formatted_address}
            icon="home_pin"
            color="gray-700"
          />
          {project.unit && (
            <IconButtonFullWidth
              label={project.unit}
              icon="apartment"
              color="gray-700"
            />
          )}
          <IconButtonFullWidth
            label={project.expected_date}
            icon="calendar_month"
            color="gray-700"
          />
          {project.description && (
            <IconButtonFullWidth
              label={project.description}
              icon="edit_note"
              color="gray-700"
            />
          )}
          <IconButtonFullWidth
            label={formatDistancetoNow(new Date(project.createdAt), {
              addSuffix: true,
              locale: dateFnsLocale
            })}
            icon="timer"
            color="gray-700"
          />

    </div>

    </> );
}
 
export default ProjectLabel;