import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const CtaButton = ( {title, icon, to}) => {
  return (
    <div className="user-cta">
        <Link to={to}>
          <motion.span
          whileHover={{scale: 0.98, rotate: '0.2deg'}}
          whileTap={{scale: 0.95, rotate: '0.4deg'}}
            className="bg-gray-900 text-white btn d-block btn-lg text-hover-white"
          >
            {title} &nbsp;{" "}
            <span className="material-symbols-outlined fs-5">{icon}</span>
          </motion.span>
        </Link>
    </div>
  );
};

export default CtaButton;
