import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { MasterContext } from "../../context/MasterContext";
import ServicesAdmin from "./ServicesAdmin";
import HomeAdmin from "./HomeAdmin";
import ClientAdmin from "../../components/Admin/ClientAdmin";

const DasboardMaster = () => {
  const { master, logout } = useContext(MasterContext);
  const drawerWidth = 240;
  const [selected, setSelected] = useState(0);

  const menuOptions = [
    {
      label: "Home",
      icon: "weekend",
    },
    {
      label: "Services",
      icon: "handyman",
    },
    {
      label: "Clients",
      icon: "supervised_user_circle",
    },
    {
      label: "Providers",
      icon: "person_apron",
    },
  ];

  const userActions = [
    {
      label: "Log out",
      icon: "door_open",
      action: logout,
    },
  ];

  const handleClick = (index) => {
    setSelected(index);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          color="blue"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
          }}
        >
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              <h3 className="text-white">Welcome {master.email}</h3>
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          color="black"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar />
          <Divider />
          <List>
            {menuOptions.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => handleClick(index)}>
                  <ListItemIcon>
                    <span className="material-symbols-outlined">
                      {item.icon}
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {userActions.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => item.action()}>
                  <ListItemIcon>
                    <span className="material-symbols-outlined">
                      {item.icon}
                    </span>
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Toolbar />
          <div className="w-100 bg-light" style={{ minHeight: "100vh" }}>
            <div
              className="w-100"
              style={{
                minHeight: "100vh",
                backgroundImage: "url('./images/header-bg.jpg",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 300px",
              }}
            >
              <div
                className="content flex-row-fluid"
                style={{ padding: "calc(10vh + 100px) calc(5vw + 60px)" }}
              >
                {selected === 0 && <HomeAdmin />}
                {selected === 1 && <ServicesAdmin />}
                {selected === 2 && <ClientAdmin />}
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default DasboardMaster;
