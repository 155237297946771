import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const UserInfoDetail = ({
  label,
  data,
  verifiable,
  verified,
  handleVerify,
}) => {
  const {t} = useTranslation()
  return (
    <div className="flex flex-row d-flex justify-content-between mb-4">
      <div className="d-inline-flex flex-column">
        <span className="fs-9 text-muted">{label}</span>
        <span className="fs-7 text-gray-800" style={{ minHeight: 18 }}>
          {data}
        </span>
      </div>
      {verifiable && (
        <>
          {verified ? (
            <Button size="small" className="certified-label">
              <span className="material-symbols-outlined ">verified</span>
              {t("profile.verified")}
            </Button>
          ) : (
            <span
              className="btn btn-sm btn d-inline-flex btn-outline border-success text-success text-hover-white bg-hover-success"
              onClick={handleVerify}
            >
              {t("profile.verify")}
            </span>
          )}{" "}
        </>
      )}
    </div>
  );
};

export default UserInfoDetail;
