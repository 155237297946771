import { TextField } from "@mui/material";
import React, { useState } from "react";
import  { usePlacesWidget } from "react-google-autocomplete";
import { useTranslation } from "react-i18next";

const UserProfileEdit = ({ setLayout, myUserData, type, user, setUserProfile = () => {}}) => {
  const [userData, setUserData] = useState(myUserData);
  const {t} = useTranslation()


  const handleSave = () => {
    //send data to the api
    console.log("sending data to the api");
    //go back to info mode
    const updateProfile = async () =>{
      const response = await fetch(`/api/${type}/profile/${userData._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        },
        body: JSON.stringify(userData),
      })
      if(response.ok){
        const newUser = await response.json()
        const userNoPsswd = newUser.user
        setUserProfile(userNoPsswd)
      } else {

      }
    }
    updateProfile()
    setLayout("info")
    
  };

  const { ref: materialRef } = usePlacesWidget({
    //apiKey: process.env.REACT_APP_GOOGLE,
    apiKey: 'AIzaSyARiMixAwwJX993AQKtG-qkOh3UyQPKo6k',
    onPlaceSelected: (place) => 
      setUserData({...userData, formatted_address: place.formatted_address}),
    inputAutocompleteValue: "ar",
    options: {
        types: ["address"],
        componentRestrictions: { country: 'ar' },
    },
    defaultValue:userData?.formatted_address
    
});

  return (
    <div>
    <div className="pb-3">
      <TextField
        variant="outlined"
        label={t("profile.ID")}
        size="large"
        fullWidth
        value={userData?.id}
        onChange={(e) => setUserData({ ...userData, id: e.target.value})}
      />
    </div>
      {type === "provider" && <div className="pb-3">
        <TextField
          variant="outlined"
          label={t("profile.company")}
          size="large"
          fullWidth
          value={userData?.company}
          onChange={(e) => setUserData({ ...userData, company: e.target.value})}
        />
      </div> }
      <div className="pb-3">
        <TextField
          variant="outlined"
          label={t("profile.name")}
          size="large"
          fullWidth
          value={userData?.first_name}
          onChange={(e) => setUserData({ ...userData, first_name: e.target.value})}
        />
      </div>
      <div className="pb-3">
        <TextField
          variant="outlined"
          label={t("profile.last_name")}
          size="large"
          fullWidth
          value={userData?.last_name}
          onChange={(e) => setUserData({ ...userData, last_name: e.target.value})}
        />
      </div>
      <div className="pb-3">
      <TextField
                size={'large'}
                fullWidth
                color="primary"
                variant="outlined"
                inputRef={materialRef}
                label={t("profile.address")}
                placeholder={userData?.formatted_address}
                sx={{ width: '100%' }}                
            />
      </div>
      <div className="pb-3">
        <TextField
          variant="outlined"
          label={t("profile.unit")}
          size="large"
          fullWidth
          value={userData?.unit}
          onChange={(e) => setUserData({ ...userData, unit: e.target.value})}

        />
      </div>

      <div className="pb-3">
        <TextField
          variant="outlined"
          label={t("profile.phone")}
          size="large"
          fullWidth
          value={userData?.phone}
          onChange={(e) => setUserData({ ...userData, phone: e.target.value})}
        />
      </div>

      <div className="pb-3">
        <TextField
          variant="outlined"
          label={t("profile.phone_2")}
          size="large"
          fullWidth
          value={userData?.second_phone}
          onChange={(e) => setUserData({ ...userData, second_phone: e.target.value})}
        />
      </div>
      <div className="how-it-works-options">
        <span
          className="btn btn-sm w-100 btn-light mx-1"
          onClick={()=>setLayout("info")}
        >
          {t("profile.cancel")}
        </span>
        <span
          className="btn btn-sm w-100 btn-mc btn-sm mx-1"
          onClick={handleSave}
        >
          {t("profile.save")}
        </span>
      </div>
    </div>
  );
};

export default UserProfileEdit;
