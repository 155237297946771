import React, { useContext, useState } from "react";
import { RequestContext } from "../../context/RequestContext";
import { AuthContext } from "../../context/AuthContext";
import { ProjectContext } from "../../context/ProjectContext";
import { useNavigate } from "react-router-dom";
import SearchFormTop from "../../components/common/RequestForm/SearchFormTop";
import SearchAddressFormTop from "../../components/common/RequestForm/SearchAddressFormTop";
import AddressUnit from "../../components/common/RequestForm/AddressUnit";
import ExpectedDate from "../../components/common/RequestForm/ExpectedDate";
import Description from "../../components/common/RequestForm/Description";
import RibbonTitle from "../../components/common/RibbonTitle";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderClient from "../../components/common/HeaderClient";
import { pageVariants } from "../../utils/HomeUtils";
import { motion } from 'framer-motion'
import MoreProviders from "../../components/common/RequestForm/MoreProviders";

const RequestFlow = () => {
  let navigate = useNavigate();
  const [error, setError] = useState("");
  const { setCurrentProject, setProjects } = useContext(ProjectContext);
  const {
    service,
    address,
    unit,
    removeService,
    removeAddress,
    removeAddressUnit,
    expectedDate,
    expiryDate,
    moreProviders,
    description,
    removeDescription,
    removeExpectedDate,
    removeExpiryDate,
    removeMoreProviders,
  } = useContext(RequestContext);
  const { user } = useContext(AuthContext);

  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      navigate("/login");
      return;
    }

    const userProject = {
      service_id: service.id,
      address: {
        formatted_address: address.address,
        lat: address.lat,
        lng: address.lng,
      },
      unit,
      expected_date: expectedDate.id,
      expiry_date: expiryDate,
      more_providers: moreProviders,
      description,
    };

    try {
      const response = await fetch("/api/userProject", {
        method: "POST",
        body: JSON.stringify(userProject),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (!response.ok) {
        const json = await response.json();
        setError(json.error);
      } else {
        setError(""); // Ensure error is cleared on success
        // Assuming these functions correctly reset context state or navigate as needed
        removeAddress();
        removeAddressUnit();
        removeDescription();
        removeExpectedDate();
        removeExpiryDate();
        removeMoreProviders();
        removeService();
        setProjects(); // Ensure this function either accepts the necessary arguments or is correctly implemented to update state
        setCurrentProject(userProject._id); // Ensure you're setting the correct project ID
        navigate("/userprojects");
      }
    } catch (error) {
      console.error("Failed to submit user project:", error);
      setError("An error occurred while submitting your project.");
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 }
  };

  return (
    <HeaderClient>
      <motion.div variants={itemVariants} initial="hidden" animate="visible">
        <div className="card card-flush card-shadow">
          <RibbonTitle title={t("request.title")} />
          <div className="card-body">
            <p>{t("request.description")}</p>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 my-3">
                  <SearchFormTop />
                </div>
                <div className="col-lg-6 my-3">
                  <SearchAddressFormTop />
                </div>
                <div className="col-lg-6 my-3">
                  <AddressUnit />
                </div>
                <div className="col-lg-6 my-3">
                  <ExpectedDate />
                </div>
                <div className="col-12 my-3">
                  <Description />
                </div>
                <div className="col-12 my-3">
                  <MoreProviders />
                </div>
                <div className="col-12 my-3">
                  <motion.span
                    whileHover={{ scale: 0.98, rotate: '0.2deg' }}
                    whileTap={{ scale: 0.95, rotate: '0.4deg' }}
                    className={`btn-${expectedDate && address && service ? 'mc' : 'secondary disabled'} btn btn-lg d-block`}
                    onClick={handleSubmit}
                  >
                    {t("request.view_options")}
                  </motion.span>
                </div>
              </div>
            </div>
            {error && <div className="error">{error}</div>}
          </div>
        </div>
      </motion.div>
      <div className="mt-6">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-6">
              <h4 className="text-white text-center">{t("request.footer_cta")}</h4>
            </div>
            <div className="col-lg-6 mt-6">
              <Link to="/userprojects">
                <motion.span
                  whileHover={{ scale: 0.98, rotate: '0.2deg' }}
                  whileTap={{ scale: 0.95, rotate: '0.4deg' }}
                  className="btn btn-mc btn-lg d-block"
                >
                  {t("request.footer_cta_button")}
                </motion.span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </HeaderClient>
  );
};

export default RequestFlow;
