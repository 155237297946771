// useFetchWithAuth.js
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { ProviderContext } from '../context/ProviderContext';

const useFetchWithAuth = () => {
  const { logout } = useContext(AuthContext);
  //const { logout: logoutProvider } = useContext(ProviderContext);

  const fetchWithAuth = async (url, options = {}) => {
    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        // If unauthorized, log the user out
        logout();
        //logoutProvider();
        // Optionally, throw an error or return a specific result to handle this case
        throw new Error('Unauthorized access, logging out...');
      }
      return response;
    } catch (error) {
      // Handle or throw the error
      throw error;
    }
  };

  return fetchWithAuth;
};

export default useFetchWithAuth;