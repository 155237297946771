// MessageItem.js
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion'
import formatDistancetoNow from "date-fns/formatDistanceToNow";
import { enUS, es, ptBR } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { differenceInHours } from 'date-fns';

function MessageItem({ isLastItem, message, myId, index }) {
  const { i18n } = useTranslation();
  const locales = { en: enUS, es: es, br: ptBR }; // Add more locales as needed
  const dateFnsLocale = locales[i18n.language] || enUS; // Default to English if locale not found

  const itemVariants = {
    hidden: { opacity: 0, x: -10 }, // Initial state of the item
    visible: i => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2, // Delay each item based on its index
      },
    }),
  };

  const subtitleVariants = {
    hidden: { opacity: 0, y: -10 }, // Initial state of the item
    visible: i => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.2, // Delay each item based on its index
      },
    }),
  };

  return (
    <AnimatePresence>
      <>
        <motion.div
          variants={itemVariants}
          initial="hidden"
          animate="visible"
          custom={index}
          className={`d-flex my-1 justify-content-${myId === message.senderId ? 'end ms-8' : 'start me-8'}`} >
          <div className={`p-3 rounded text-gray-900 fw-semibold fs-7 mw-lg-400px bg-light-${myId === message.senderId ? 'primary text-start' : 'info text-end'}`}>
            {message.text}
          </div>
        </motion.div>
        {isLastItem && <motion.div
          variants={subtitleVariants}
          initial="hidden"
          animate="visible"
          custom={index + 10}
          className={`d-flex my-2 justify-content-${ myId === message.senderId ? 'end' : 'start'}`} >
           <small className='text-muted'>{formatDistancetoNow(new Date(message.createdAt), {
            addSuffix: true,
            locale: dateFnsLocale
          })}</small>
        </motion.div>}
      </>
    </AnimatePresence>
  );
}

export default MessageItem;