import React, { useCallback, useContext, useEffect, useState } from "react";
import HeaderProvider from "../../components/common/HeaderProvider";
import { useTranslation } from "react-i18next";
import NoProjects from "../../components/Request/NoProjects";
import { ProjectContext } from "../../context/ProjectContext";
import ReviewCard from "../../components/Request/ReviewCard";
import { motion } from 'framer-motion';

const ReviewsProviders = () => {
const {t} = useTranslation()
const {retrieveReviews} = useContext(ProjectContext)
const [reviews, setReviews] = useState()

const getReviews = useCallback( async()=>{
  setReviews(await retrieveReviews())
},[retrieveReviews])

useEffect(()=>{
  getReviews();
},[getReviews])

const containerVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { opacity: 1, scale: 1 }
};

  return (
    <HeaderProvider>
      {reviews && reviews.length > 0 && 
      (<motion.div
        className="container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >


        {reviews.map((review,index)=> 
        <motion.div key={index} variants={itemVariants}><ReviewCard key={index} review={review} getReviews={getReviews}/></motion.div>
        )}
        </motion.div>)}


      {reviews && reviews.length === 0 && <motion.div variants={itemVariants}><NoProjects icon="sentiment_satisfied" title={t("home_provider.no_reviews")}/></motion.div>}


    </HeaderProvider>

  );
};

export default ReviewsProviders;
