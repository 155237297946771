import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion"; // Import the motion component

const ServicesDisplay = ({ services }) => {
  const [expandedCategories, setExpandedCategories] = useState({});
  const [expandedSubcategories, setExpandedSubcategories] = useState({});
  const { t } = useTranslation();

  const organizedServices = useMemo(() => {
    const temp = {};
    services.forEach((service) => {
      if (!temp[service.category]) {
        temp[service.category] = {};
      }
      if (!temp[service.category][service.subcategory]) {
        temp[service.category][service.subcategory] = [];
      }
      temp[service.category][service.subcategory].push(service);
    });
    return temp;
  }, [services]);

  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({ ...prev, [category]: !prev[category] }));
  };

  const toggleSubcategory = (category, subcategory) => {
    setExpandedSubcategories((prev) => ({
      ...prev,
      [`${category}-${subcategory}`]: !prev[`${category}-${subcategory}`],
    }));
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1, // Delay between each child animation
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="w-100"
      >
        {Object.entries(organizedServices).map(([category, subcategories], index) => (
          <motion.div className="w-100" key={category} variants={itemVariants}>
            <div
              onClick={() => toggleCategory(category)}
              className="d-flex flex-row justify-content-between align-items-center w-100 btn btn-md btn-outline border border-warning text-warning my-3"
            >
              <span>{category}</span>
              <span className="btn btn-icon material-symbols-outlined">
                expand_more
              </span>
            </div>
            {expandedCategories[category] && (
              <motion.div variants={containerVariants}>
                {Object.entries(subcategories).map(([subcategory, services]) => (
                  <motion.div className="ps-6" key={subcategory} variants={itemVariants}>
                    <div
                      className="d-flex flex-row justify-content-between align-items-center w-100 btn btn-md btn-light my-3"
                      onClick={() => toggleSubcategory(category, subcategory)}
                    >
                      <span>{subcategory}</span>
                      <span className="btn btn-icon material-symbols-outlined">
                        expand_more
                      </span>
                    </div>
                    {expandedSubcategories[`${category}-${subcategory}`] && (
                      <motion.div className="ps-6" variants={containerVariants}>
                        {services.map((service) => (
                          <motion.div
                            className="d-flex flex-row justify-content-start align-items-center btn btn-sm btn-light my-3"
                            key={service._id}
                            variants={itemVariants}
                          >
                            <span className="material-symbols-outlined me-4">
                              handyman
                            </span>
                            <span>{service.label}</span>
                          </motion.div>
                        ))}
                      </motion.div>
                    )}
                  </motion.div>
                ))}
              </motion.div>
            )}
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default ServicesDisplay;