import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button'
import { ProjectContext } from "../../context/ProjectContext";
import { useTranslation } from "react-i18next";
import { ProviderContext } from "../../context/ProviderContext";
import { CurrentContext } from "../../context/CurrentContext";

const NavbarProvider = ({color }) => {
  const { logout, provider } = useContext(ProviderContext);
  const {logoutProjects} = useContext(ProjectContext)
  const {changeIsClient} = useContext(CurrentContext)

  const {t} = useTranslation()

  const navigate = useNavigate()

  const handleLogout = () => {
    logoutProjects()
    logout();
  };

  const handleClick = () => {
    changeIsClient(true)
    navigate('/')
  }

  return (
    <header>
      <div className="container header-maestro">
        <Link to="/homeproviders">
        <p style={{textAlign:'left'}}> 
          <img src={require(`../../images/logo/mc-${color}.png`)} className="logo-mobile" alt="Maestro Clave logo" />
          </p>
        </Link>
        <nav>
          {provider ? (
            <div>
              <div>
                <span className={`nav-email${color}`}  >{provider.email}</span>
                <Button 
                onClick={handleLogout} 
                variant="outlined"
                size="small"
                margin="normal"
                color="primary"
                className={`log-out-button${color}`}
                >{t("nav.logout")}</Button>
              </div>
            </div>
          ) : (
            <div>
              <Link to="/loginproviders">
              <Button 
                variant="contained"
                size="small"
                margin="normal"
                color="primary"
                className={`log-in-button${color}`}
                >{t("nav.login")}</Button>
              </Link>
              <Link to="/signupproviders">
                <Button 
                style={{marginLeft:'8px'}}
                variant="outlined"
                size="small"
                margin="normal"
                color="primary"
                className={`log-out-button${color}`}
                >{t("nav.signup")}</Button>
                </Link>
            </div>
          )}
          <div>
            <Button
            variant="outlined"
            size="small"
            margin="normal"
            color="primary"
            className={`log-out-button${color} mt-3 w-100 ml-0 btn btn-link `}
            onClick={handleClick}>{t('home_provider.client_button')}</Button>
          
          </div>
        </nav>
      </div>
    </header>
  );
};

export default NavbarProvider;
