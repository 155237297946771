import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import UserInfo from "../../components/Profile/UserInfo";
import UserProfileEdit from "../../components/Profile/UserProfileEdit";
import UserChangePassword from "../../components/Profile/UserChangePassword";
import HeaderClient from "../../components/common/HeaderClient";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { pageVariants } from "../../utils/HomeUtils";

const UserProfile = () => {
  const [layout, setLayout] = useState("info");
  const [error, setError] = useState("");

  const { user, userProfile, setUserProfile, newPassword } =
    useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    const getUserProfileInfo = async () => {
      const response = await fetch("api/user/profile", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (response.ok) {
        const json = await response.json();
        const user = json.user;
        delete user.password;
        setUserProfile(user);
      } else {
        setError("no response from server");
      }
    };
    if (user) {
      getUserProfileInfo();
    }
  }, [user]);
  const itemVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 },
  };

  return (
    <HeaderClient>
      <motion.div variants={itemVariants} initial="hidden" animate="visible">
        <div className="card card-flush card-shadow mb-6">
          <div className="card-header">
            <h3 className="card-title">{t("profile.profile")}</h3>
          </div>
          <div className="card-body">
            {layout === "info" && (
              <UserInfo user={userProfile} setLayout={setLayout} />
            )}
            {layout === "edit" && (
              <UserProfileEdit
                myUserData={userProfile}
                type="user"
                setLayout={setLayout}
                user={user}
                setUserProfile={setUserProfile}
              />
            )}
            {layout === "password" && (
              <UserChangePassword
                setLayout={setLayout}
                newPassword={newPassword}
              />
            )}
          </div>
          {error && <div className="error">{error}</div>}
        </div>
      </motion.div>
    </HeaderClient>
  );
};

export default UserProfile;
