import { useContext, useState } from "react";
import SignUpTitle from "../../components/SignUp/SignUpTitle";
import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../../components/common/Footer";
import { ProviderContext } from "../../context/ProviderContext";
import NavbarProvider from "../../components/common/NavbarProvider";

const SignupProviders = () => {
  const { t } = useTranslation();
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [error, setError] = useState("");

  const { signup } = useContext(ProviderContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!email || !password || !first_name || !last_name || !company) {
      setError(t("signup.all_fields_error"));
    } else {
      const why = await signup(email, password, first_name, last_name, company);
      if (why && why.error != null) {
        setError(why.error);
      } else {
        setError("");
      }
    }
  };
  return (
    <div className="mh-75vh bg-gray-900">
      <div className="home-provider-title">
        <NavbarProvider color="white" />
        <div className="inner-wrap">
          <div className="boxed">
            <SignUpTitle />
          </div>
        </div>
      </div><div className="inner-wrap mt--100 px-10">
        <div className="boxed pb-100">
        <div className="card card-flush">
          <div className="card-header">
            <h3 className="card-title text-capitalize">
              {t("signup.signup")}
            </h3>
            </div>
            <div className="card-body">
            <form className="sign-up" onSubmit={handleSubmit}>
            <div className="row">
                    <div className="col-lg-6 my-3">
              <TextField
                fullWidth
                autoComplete="name"
                type="text"
                label={t("signup.first_name")}
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
              />
              </div>
                    <div className="col-lg-6 my-3">
              <TextField
                fullWidth
                autoComplete="lastname"
                type="text"
                label={t("signup.last_name")}
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
              />
              </div>
                    <div className="col-lg-6 my-3">
              <TextField
                fullWidth
                autoComplete="company"
                type="text"
                label={t("signup.company")}
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
              </div>
                    <div className="col-lg-6 my-3">
              <TextField
                fullWidth
                autoComplete="email"
                type="text"
                label={t("signup.email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              </div>
                    <div className="col-lg-6 my-3">
              <TextField
                fullWidth
                autoComplete="current-password"
                type="password"
                label={t("signup.password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              </div>
              </div>
              <div className="row">
                    <div className="col-lg-12 col-12 my-3">
              <Button
                fullWidth={true}
                variant="contained"
                margin="normal"
                className="maestro-orange-button text-capitalize"
                onClick={handleSubmit}
              >
                {" "}
                {t("signup.signup")} &nbsp;{" "}
                <span className="material-icons md-light">person_add</span>
              </Button>
              </div>
              </div>
              <div className="error-message">{error}</div>
            </form>
          </div>
        </div>
        </div>
        </div>
      <Footer />
    </div>
  );
};

export default SignupProviders;
