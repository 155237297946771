import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import Navbar from "../../components/common/NavbarClient";
import SignUpTitle from "../../components/SignUp/SignUpTitle";
import { Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import Footer from "../../components/common/Footer";
import {motion} from 'framer-motion'
import {pageVariants} from '../../utils/HomeUtils'

const Signup = () => {
  const { t } = useTranslation();
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { signup } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!email || !password || !first_name || !last_name) {
      setError(t("signup.all_fields_error"));
    } else {
      const why = await signup(email, password, first_name, last_name, phone);
      if (why && why.error != null) {
        setError(why.error);
      } else {
        setError("");
      }
    }
  };
  return (
    <motion.div
      key="user_login"
      initial
      animate
      exit
      variants={pageVariants}
      transition={{ duration: 3 }}
      className="bg-gray-900"
    >
      <div className="home-client-title">
        <Navbar color="white" />
        <div className="inner-wrap">
          <div className="boxed">
            <SignUpTitle />
          </div>
        </div>
      </div>
      <div className="outer-wrap mt--100 px-10">
        <div className="boxed pb-100">
            <div className="card card-flush">
              <div className="card-header">
                <h3 className="card-title text-capitalize">{t("signup.signup")}</h3>
              </div>
              <div className="card-body">
                <form className="sign-up container" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 my-3">
                  <TextField
                    fullWidth
                    autoComplete="name"
                    type="text"
                    label={t("signup.first_name")}
                    value={first_name}
                    onChange={(e) => setFirstName(e.target.value)}
                    />

                    </div>
                    <div className="col-lg-6 my-3">
                  <TextField
                    fullWidth
                    autoComplete="lastname"
                    type="text"
                    label={t("signup.last_name")}
                    value={last_name}
                    onChange={(e) => setLastName(e.target.value)}
                    />

                    </div>
                    <div className="col-lg-6 my-3">
                  <TextField
                    fullWidth
                    autoComplete="email"
                    type="text"
                    label={t("signup.email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />

                    </div>
                    <div className="col-lg-6 my-3">
                  <TextField
                    fullWidth
                    autoComplete="phone"
                    type="text"
                    label={t("signup.phone")}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    />

                    </div>
                    <div className="col-lg-6 my-3">
                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type="password"
                    label={t("signup.password")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    />

                    </div>
                    <div className="col-lg-12 my-3">
                  <Button
                    fullWidth={true}
                    variant="contained"
                    margin="normal"
                    className="maestro-orange-button text-capitalize"
                    onClick={handleSubmit}
                    >
                    {" "}
                    {t("signup.signup")} &nbsp;{" "}
                    <span className="material-icons md-light">person_add</span>
                  </Button>

                    </div>


                    </div>
                  <div className="error-message">{error}</div>
                </form>
              </div>
            </div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
};

export default Signup;
