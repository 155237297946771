import React, { useContext, useState } from "react";
import { RequestContext } from "../../../context/RequestContext";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const AddressUnit = () => {
  const {t} = useTranslation()
  const { unit, setContextUnit } = useContext(RequestContext);
  const [unitValue, setUnitValue] = useState(unit);
  const setUnitForm = (value) => {
    setUnitValue(value);
    setContextUnit(value);
  };
  return (
    <div className="confirmation-fields">
      <TextField
        variant="outlined"
        label={t("profile.unit")}
        size="large"
        fullWidth
        value={unitValue}
        onChange={(e) => setUnitForm(e.target.value)}
      />
    </div>
  );
};

export default AddressUnit;
