import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../context/ProjectContext";
import RequestProviderCard from "../../components/Request/RequestProviderCard";
import { useTranslation } from "react-i18next";
import NoProjects from "../../components/Request/NoProjects";
import HeaderProvider from "../../components/common/HeaderProvider";
import { CurrentContext } from "../../context/CurrentContext";
import Skeleton from "react-loading-skeleton";


const RequestProviders = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true)

  const { getProvidersRequests } = useContext(ProjectContext);
  const { t } = useTranslation();
  const {changeIsClient} = useContext(CurrentContext)

  const getAllRequests = async () => {
    const response = await getProvidersRequests();
    if (response && response.length) {
      setRequests(response);
      setLoading(false)
    }
  };

  useEffect(() => {
    getAllRequests();
    changeIsClient(false)

  }, []);

  return (
    <HeaderProvider title={t("request.my_projects")}>
      {loading && (
          <div className="col-lg-6">
          <div className="card card-flush">
            <div className="card-header">
              <div className="card-title">
                <Skeleton count={1} />
              </div>
            </div>
            <div className="card-body">
              <Skeleton count={3} />
            </div>
          </div>
          </div>
        )}
      {!loading && requests &&
        requests.length > 0 &&
        requests
          .filter((request) => request.status !== "archived")
          .map((request) => {
            return (
              <RequestProviderCard
                getAllRequests={getAllRequests}
                key={request._id}
                request={request}
              />
            );
          })}
      {!loading && requests && requests
          .filter((request) => request.status !== "archived").length === 0 && (
        <NoProjects
          icon="sentiment_satisfied"
          title={t("request.no_provider_project")}
          description={t("request.no_provider_project_desc")}
        />
      )}
    </HeaderProvider>
  );
};

export default RequestProviders;
