import React from "react";
import HomeForm from "../../components/Home/HomeForm";
import HomeCategories from "../../components/Home/HomeCategories";
import HowItWorks from "../../components/Home/HowItWorks";
import HomeTitle from "../../components/Home/HomeTitle";
import { Parallax } from "react-scroll-parallax";
import { useTranslation } from "react-i18next";
import Footer from "../../components/common/Footer";
import NavbarClient from "../../components/common/NavbarClient";
import { motion } from "framer-motion";
import { pageVariants } from "../../utils/HomeUtils";

const HomeClient = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="home-client-title">
        <NavbarClient color="white" />
        <div className="inner-wrap">
          <div className="boxed">
            <HomeTitle
              accent_top_one={t("home_client.accent_top_one")}
              accent_top_two={t("home_client.accent_top_two")}
              title={t("home_client.title")}
              subtitle={t("home_client.subtitle")}
            />
          </div>
        </div>
      </div>
      <div className="inner-wrap mt--100">
        <div className="boxed">
          <HomeForm
            search_title={t("home_client.search_title")}
            search_button={t("home_client.search_button")}
          />
        </div>
      </div>
      <div className="inner-wrap">
        <div className="boxed category-boxed">
          <Parallax speed={-2}>
              <HomeCategories
                categories_title={t("home_client.categories_title")}
                edit={true}
              />
          </Parallax>
        </div>
      </div>
      <div className="inner-wrap">
        <div className="boxed">
          <HowItWorks
            title={t("home_client.how_it_works_title")}
            description={t("home_client.how_it_works_description")}
            steps={t("home_client.how_it_works_steps", { returnObjects: true })}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomeClient;
