import React from 'react';

const NoProjects = ({icon, title, description, cta, className}) => {
    return ( 
    <div className={`card ${className}`}>
        <div className="card-body d-flex flex-column justify-content-center">
            <p className='material-symbols-outlined text-gray-300 text-center' style={{fontSize:50}}>{icon}</p>
            <h4 className="text-center pb-4">{title}</h4>

            <p className="text-center pb-8">{description}</p>
            {cta}
        </div>

    </div>
     );
}
 
export default NoProjects;