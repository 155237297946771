import React, { createContext, useState, useCallback } from "react";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem("user")));
  const [userProfile, setUserProfile] = useState(() => JSON.parse(localStorage.getItem("userProfile")));

  const login = useCallback(async (email, password) => {
    try {
      const response = await fetch("/api/user/login", {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "An error occurred during login.");
      }

      const loggedUser = await response.json();
      setUser(loggedUser);
      localStorage.setItem("user", JSON.stringify(loggedUser));
    } catch (error) {
      console.error("Login error:", error.message);
      throw error; // Rethrow to let the calling component handle it
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("user");
    setUser(null);
    localStorage.removeItem("userProfile");
    setUserProfile(null);
  }, []);

  const signup = useCallback(async (email, password, first_name, last_name, phone) => {
      const response = await fetch("/api/user/signup", {
      method: "POST",
      body: JSON.stringify({ email, password, first_name, last_name,phone }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json = await response.json();
    if (response.ok) {
      login(email,password)
    } else {
      return json
    }
  }, [login]);

  const setUserProfileData = useCallback((profileData) => {
    setUserProfile(profileData);
    localStorage.setItem("userProfile", JSON.stringify(profileData));
  }, []);

  const listUsers = useCallback(async (token) => {
    try {
      const response = await fetch("/api/user/list", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to fetch user list.");
      }

      if (response.status === 401) {
        logout()
      }

      return await response.json();
    } catch (error) {
      console.error("Error listing users:", error.message);
      throw error; // Rethrow to let the calling component handle it
    }
  }, []);

  const newPassword = useCallback(async (oldPassword, newPassword) => {
    if (!userProfile || !userProfile._id) {
      console.error("User profile is undefined or missing _id");
      return { success: false, data: "User profile is undefined or missing _id" };
    }

    try {
      const response = await fetch(`/api/user/pwd/${userProfile._id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ new_password: newPassword, old_password: oldPassword }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "An error occurred while changing the password.");
      }
      if (response.status === 401) {
        logout()
      }

      return { success: true };
    } catch (error) {
      console.error("Password change error:", error.message);
      return { success: false, data: error.message };
    }
  }, [user, userProfile]);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        signup,
        userProfile,
        setUserProfile: setUserProfileData,
        newPassword,
        listUsers,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;