import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import WorkoutContextProvider from "./context/WorkoutContext";
import AuthContextProvider from "./context/AuthContext";
import ProjectContextProvider from "./context/ProjectContext";
import ProviderContextProvider from "./context/ProviderContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RequestContextProvider from "./context/RequestContext";
import StyleContextProvider from "./context/StyleContext";
import { ParallaxProvider } from "react-scroll-parallax";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import CurrentContextProvider from "./context/CurrentContext";
import MasterContextProvider from "./context/MasterContext";
import LoadingApp from "./pages/LoadingApp";
//import { orange, purple } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(255,78,80)",
    },
    secondary: {
      main: "rgb(249,214,46)",
    },
    black:{
      main: "#222"
    },
    blue: {
      main: "rgb(47,82,201)"
    }
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StyleContextProvider>
        <AuthContextProvider>
          <MasterContextProvider>
            <ProviderContextProvider>
              <CurrentContextProvider>
                <RequestContextProvider>
                  <ProjectContextProvider>
                    <WorkoutContextProvider>
                      <ParallaxProvider>
                        <BrowserRouter>
                          <Suspense fallback={<LoadingApp />}>
                            <App />
                          </Suspense>
                        </BrowserRouter>
                      </ParallaxProvider>
                    </WorkoutContextProvider>
                  </ProjectContextProvider>
                </RequestContextProvider>
              </CurrentContextProvider>
            </ProviderContextProvider>
          </MasterContextProvider>
        </AuthContextProvider>
      </StyleContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
