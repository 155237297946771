import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Rating } from "react-simple-star-rating";
import { ProviderContext } from "../../context/ProviderContext";
import { RequestContext } from "../../context/RequestContext";
import ServicesDisplay from "./ServicesDisplay";
import AreasDisplay from "./AreasDisplay";
import { motion } from "framer-motion";
import { cardAnimation } from "../../utils/HomeUtils";


const ProviderSummary = () => {
  const { t } = useTranslation();
  const { providerProfile } = useContext(ProviderContext);
  const { allServices } = useContext(RequestContext);
  const [matchedServices, setMatchedServices] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (providerProfile && providerProfile.services) {
      setMatchedServices(
        allServices.filter((detailedService) =>
          providerProfile.services.find(
            (service) => service.service_id === detailedService.id
          )
        )
      );
    }
    if (providerProfile && providerProfile.locations) {
      setLocations(
        providerProfile.locations.map((value) => value.formatted_address)
      );
    }
  }, [providerProfile, allServices]);

  return (
    // Use the motion.div component with the animation props
    <motion.div className="card card-flush"
      initial={cardAnimation.initial}
      animate={cardAnimation.animate}
      transition={cardAnimation.transition}
    >
      <div className="card-header">
        <div className="d-flex flex-column">
          <h3 className="card-title">{providerProfile.company}</h3>
          <span className="text-muted">{t("home_provider.provider_summary")}</span>
        </div>
        <div className="card-toolbar">
          <div className="d-flex flex-column">
            <div className="request-card-stars">
              <Rating
                size={16}
                initialValue={providerProfile.stars}
                transition={true}
                readonly={true}
                allowFraction={true}
              />{" "}
              <small className="stars-label text-muted fw-bold fs-7">
                {providerProfile.stars} {t("request.out_of_5")}
              </small>
            </div>
            {providerProfile.reviews && (
              <span className="text-center">
                {providerProfile.reviews} {t("request.reviews")}{" "}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="card-body">
        <motion.div className="border rounded border-solid p-4 my-8 shadow"
          initial={cardAnimation.initial}
          animate={cardAnimation.animate}
          transition={cardAnimation.transition}
        >
          <h3>{t("home_provider.services_offered")}</h3>
          {matchedServices.length > 0 ? (
            <>
              <p className="text-muted">
                {matchedServices.length} {t("home_provider.services_selected")}{" "}
                {t("home_provider.expand_by_category")}
              </p>
              <ServicesDisplay services={matchedServices} />
            </>
          ) : (
            <span className="btn btn-light-warning d-block my-3">
              {t("home_provider.no_services_found")}
            </span>
          )}
        </motion.div>
        <motion.div className="border rounded border-solid p-4 my-8 shadow"
          initial={cardAnimation.initial}
          animate={cardAnimation.animate}
          transition={cardAnimation.transition}
        >
          <h3>{t("home_provider.service_area")}</h3>
          {locations && locations.length > 0 ? (
            <>
              <p className="text-muted">
                {locations.length} {t("home_provider.areas_selected")}
              </p>
              <AreasDisplay locations={locations} />
            </>
          ) : (
            <>
              <span className="btn btn-light-warning d-block my-3">
                {t("home_provider.no_areas_found")}
              </span>
            </>
          )}
        </motion.div>
        {/* Show Plan and button to upgrade */}
      </div>
    </motion.div>
  );
};

export default ProviderSummary;