import React, { useContext, useState } from "react";
import UserInfo from "../../components/Profile/UserInfo";
import UserProfileEdit from "../../components/Profile/UserProfileEdit";
import ProviderChangePassword from "../../components/Profile/UserChangePassword";
import { ProviderContext } from "../../context/ProviderContext";
import { useTranslation } from "react-i18next";
import HeaderProvider from "../../components/common/HeaderProvider";

const ProviderProfile = () => {
  const { t } = useTranslation();
  const [layout, setLayout] = useState("info");

  const {
    provider,
    providerProfile,
    setProviderProfile,
    newPassword,
  } = useContext(ProviderContext);

  return (
    <HeaderProvider>
      <div className="card card-flush ">

      <div className="card-header">
        <h3 className="card-title">{t("profile.profile")}</h3>
      </div>
      <div className="card-body ">
        <div>
          {layout === "info" && (
            <UserInfo user={providerProfile} setLayout={setLayout} />
            )}
          {layout === "edit" && (
            <UserProfileEdit
            myUserData={providerProfile}
            type="provider"
            setLayout={setLayout}
            user={provider}
            setUserProfile={setProviderProfile}
            />
            )}
          {layout === "password" && (
            <ProviderChangePassword
            setLayout={setLayout}
            newPassword={newPassword}
            />
            )}
        </div>
      </div>
            </div>
    </HeaderProvider>
  );
};

export default ProviderProfile;
