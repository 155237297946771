import React from "react";
import HomeCategories from "../../components/Home/HomeCategories";
import HowItWorks from "../../components/Home/HowItWorks";
import HomeTitle from "../../components/Home/HomeTitle";
import { Parallax } from "react-scroll-parallax";
import { useTranslation } from "react-i18next";
import Footer from "../../components/common/Footer";
import NavbarProvider from "../../components/common/NavbarProvider";
import { useNavigate } from "react-router-dom";

const HomeProviders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="bg-gray-900">
      <div className="home-provider-title">
        <NavbarProvider color="white" />
        <div className="inner-wrap">
          <div className="boxed">
            <HomeTitle
              accent_top_one={t("home_client.accent_top_one")}
              accent_top_two={t("home_client.accent_top_two")}
              title={t("home_provider.title")}
              subtitle={t("home_provider.subtitle")}
            />
          </div>
        </div>
      </div>
      <div className="inner-wrap mt--100 px-10">
        <div className="boxed">
          <div className="card card-flush">
            <div className="card-header">
              <div className="card-title">{t("home_provider.get_clients")}</div>
            </div>
            <div className="card-body">
              <div className="row ">
                <div className="col-lg-6 px-2">
                  <span
                    onClick={() => navigate("/loginproviders/")}
                    className="btn btn-md btn-mc d-block"
                  >
                    {t("nav.login")}
                  </span>
                </div>
                <div className="d-lg-none">
                  <p className="hr my-8 ">
                    <span>{t("home_provider.not_registered_yet")}</span>
                  </p>
                </div>
                <div className="col-lg-6 px-4">
                  <span
                    onClick={() => navigate("/signupproviders/")}
                    className="btn btn-md btn-mc d-block"
                  >
                    {t("nav.signup")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-wrap">
        <div className="boxed">
          <Parallax speed={-2}>
            <HomeCategories
              categories_title={t("home_client.categories_title")}
              edit={false}
            />
          </Parallax>
        </div>
      </div>
      <div className="inner-wrap">
        <div className="boxed">
          <HowItWorks
            title={t("home_client.how_it_works_title")}
            description={t("home_client.how_it_works_description")}
            steps={t("home_provider.how_it_works_steps", {
              returnObjects: true,
            })}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomeProviders;
