import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import ChatBox from './ChatBox';
import { fetchMessages } from '../../api/api'

const TabChat = ({ senderId, receiverId , userRequestId, myId, token}) => {
    const [messages, setMessages] = useState(null)
    
    // Define the function to fetch messages
    const getMessages = async () => {
        if (userRequestId) {
            try {
                const fetchedMessages = await fetchMessages(userRequestId, token);
                setMessages(fetchedMessages);
            } catch (error) {
                console.error('Error fetching messages:', error);
                // Handle error (e.g., show error message to the user)
            }
        }
    };

    useEffect(() => {
        getMessages(); // Fetch messages immediately on component mount

        // Set up polling with setInterval
        const intervalId = setInterval(() => {
            getMessages();
        }, 20000); // Adjust the interval as needed. Here, it's set to every 60 seconds.

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [userRequestId, token]); // Ensure useEffect runs again if request or token changes

    return (<motion.div>
        <ChatBox getMessages={getMessages} messages={messages} userRequestId={userRequestId} senderId={senderId} token={token} receiverId={receiverId} myId={myId}/>
    </motion.div>);
}

export default TabChat;