import React, { useContext, useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { ProviderContext } from '../../context/ProviderContext';
import { useTranslation } from 'react-i18next';

const BottomNavProvider = () => {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const { provider } = useContext(ProviderContext);
    const { t } = useTranslation();

    const valuesLogged = ["/homeproviderlogged", "/reviewsproviders", "/requestproviders", "/providerprofile"];
    const values = ["/homeproviders", "/whymc", "/signupproviders", "/loginproviders"];

    useEffect(() => {
        // Determine the initial navigation value based on the current path and provider status
        const currentPath = location.pathname;
        const pathList = provider ? valuesLogged : values;
        const currentIndex = pathList.indexOf(currentPath);
        setValue(currentIndex !== -1 ? currentIndex : 0);
    }, [location, provider]);

    const handleChange = (newValue) => {
        setValue(newValue);
        const newPath = provider ? valuesLogged[newValue] : values[newValue];
        navigate(newPath);
    };

    return (
        <Paper
            sx={{ position: "fixed", bottom: 7, left: `2.5%`, right: 0, width: `95%` }}
            elevation={3}
        >
            <BottomNavigation
                showLabels
                value={value}
                onChange={(e, newValue) => handleChange(newValue)}
            >
                <BottomNavigationAction
                    label={t("nav.home")}
                    icon={<span className="material-symbols-outlined">cottage</span>}
                />
                <BottomNavigationAction
                    label={provider ? t("nav.reviews") : t("nav.whymc")}
                    icon={provider ? <span className="material-symbols-outlined">reviews</span> : <span className="material-symbols-outlined">sentiment_satisfied</span>}
                />
                <BottomNavigationAction
                    label={provider ? t("nav.requests") : t("nav.signup")}
                    icon={provider ? <span className="material-symbols-outlined">request_quote</span> : <span className="material-symbols-outlined">how_to_reg</span>}
                />
                <BottomNavigationAction
                    label={provider ? t("nav.profile") : t("nav.login")}
                    icon={<span className="material-symbols-outlined">{provider ? "account_circle" : "login"}</span>}
                />
            </BottomNavigation>
        </Paper>
    );
};

export default BottomNavProvider;