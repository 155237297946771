import React, { createContext, useState } from 'react';

const StyleContext = createContext()

const StyleContextProvider = (props) => {

    const [pageClass,setPageClass] = useState('')

    return ( 
        <StyleContext.Provider value={{pageClass, setPageClass}}>
            {props.children}
        </StyleContext.Provider>
     );
}
 
export default StyleContextProvider;