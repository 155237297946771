import React, { useContext, useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { RequestContext } from "../../context/RequestContext";
import formatDistancetoNow from "date-fns/formatDistanceToNow";
import { enUS, es, ptBR } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import ClientRating from "../common/ClientRating";
import ReviewsList from "../common/ReviewsList";
import { motion } from 'framer-motion';
import { AuthContext } from "../../context/AuthContext";
import TabSummary from "./TabSummary";
import TabChat from "./TabChat";
import { ExpectedDate } from '../../data/dummyData';
import TabReview from "./TabReview";
import TabEstimateUser from "./TabEstimateUser";


const RequestCard = ({
  provider,
  providerStatus,
  project_id,
  fetchProviderOptions = () => { },
}) => {
  const { sendUserRequest } = useContext(RequestContext);
  const { user } = useContext(AuthContext)
  const [status, setStatus] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0)


  const { t, i18n } = useTranslation();
  const locales = { en: enUS, es: es, br: ptBR }; // Add more locales as needed
  const dateFnsLocale = locales[i18n.language] || enUS; // Default to English if locale not found  


  const handleClick = async () => {
    const body = {
      user_project_id: project_id,
      provider_id: provider._id,
      status: "sent",
      requestedAt: new Date()
    };

    const userRequest = await sendUserRequest(body);
    if (userRequest) {
      fetchProviderOptions();
    }
  };

  useEffect(() => {
    if (providerStatus && providerStatus.length > 0) {
      const hasStatus = providerStatus.find(
        (item) => item.provider_id === provider._id
      );
      if (hasStatus) {
        setStatus({
          user_id: hasStatus.user_id,
          status: hasStatus.status,
          requestedAt: hasStatus.requestedAt,
          contacted: hasStatus.contacted,
          seen: hasStatus.seen,
          wonAt: hasStatus.wonAt,
          _id: hasStatus._id,
          stars: hasStatus.stars ? hasStatus.stars : null,
          review: hasStatus.review ? hasStatus.review : '',
          review_response: hasStatus.review_response ? hasStatus.review_response : null
        });
      }
    }
  }, [providerStatus, provider]);

  const summaryData = status
    ? [
      {
        label: t("home_provider.address"),
        data: provider.formatted_address,
        icon: "pin_drop",
      },
      {
        label: t("request.sent"),
        data: formatDistancetoNow(new Date(status.requestedAt), {
          addSuffix: true,
          locale: dateFnsLocale,
        }),
        icon: "event_available",
      }
    ]
    : [];

  return (
    <div className="card card-flush card-shadow mb-6 h-100">
      <div className="card-header">
        <div className="d-flex flex-column w-50">
          <h3 className="card-title fw-bold mc-color-1">
            {provider.company}{" "}
            {provider.certified && (
              <span className="material-symbols-outlined">verified</span>
            )}
          </h3>

        </div>
        <div className="card-toolbar">
          <div className="d-flex flex-column">
            <div className="request-card-stars">
              <Rating size={16} initialValue={provider.stars} transition={true} readonly={true} allowFraction={true} />{" "}
              <small className="stars-label text-muted fw-bold fs-7">{provider.stars} {t("request.out_of_5")}</small>
            </div>
            {provider.reviews && (
              <span className="text-center">
                {provider.reviews} {t("request.reviews")}{" "}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="card-body">
        {status && <><ul className="nav nav-tab nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-8 fw-bold">
          <li className="nav-item cursor-pointer" onClick={() => setSelectedTab(0)}><span className={`nav-link ${selectedTab === 0 && 'active text-dark'}`}>{t("nav.request")}</span></li>
          <li className="nav-item cursor-pointer" onClick={() => setSelectedTab(1)}><span className={`nav-link ${selectedTab === 1 && 'active text-dark'}`}>{t("nav.chat")}</span></li>
          <li className="nav-item cursor-pointer" onClick={() => setSelectedTab(2)}><span className={`nav-link ${selectedTab === 2 && 'active text-dark'}`}>{t("nav.estimate")}</span></li>
          <li className="nav-item cursor-pointer" onClick={() => setSelectedTab(3)}><span className={`nav-link ${selectedTab === 3 && 'active text-dark'}`}>{t("nav.payment")}</span></li>
          <li className="nav-item cursor-pointer" onClick={() => setSelectedTab(4)}><span className={`nav-link ${selectedTab === 4 && 'active text-dark'}`}>{t("nav.reviews")}</span></li>
        </ul>

          <div className="border border-solid min-h-300px overflow-auto rounded pt-4 px-3 my-4">
            {selectedTab === 0 && <TabSummary data={summaryData} color='warning' />}
            {selectedTab === 1 && <TabChat userRequestId={status._id} senderId={status.user_id} receiverId={provider._id} myId={status.user_id} token={user.token} />}
            {selectedTab === 2 && <TabEstimateUser setSelectedTab={setSelectedTab} userRequestId={status._id} token={user.token} color='success' />}
            {selectedTab === 4 && <TabReview providerId={provider._id} status={status} setStatus={setStatus} />}
          </div></>}

        <div className="d-flex flex-row">
          {!status && (
            <motion.span
              whileHover={{
                scale: 0.98,
                rotate: '2deg'
              }}
              whileTap={{
                scale: 0.94,
                rotate: '4deg'
              }}

              className="btn btn-sm btn-mc w-100 text-capitalize m-1"
              onClick={handleClick}
            >
              {t("request.request_estimate")}
            </motion.span>
          )}</div>



        <div className="actions">
          {status && status.seen && (
            <div className="pt-6">
              <div className="btn btn-sm btn-light text-gray-700 text-capitalize mx-1">
                <span className="material-symbols-outlined fs-7">
                  archive
                </span>{" "}
                {t("request.archive")}
              </div>

            </div>
          )}
        </div>
      </div>
    </div >
  );
};

export default RequestCard;
