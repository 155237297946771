import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const AreasDisplay = ({ locations }) => {
    // Define the animation variants
    const itemVariants = {
        hidden: { opacity: 0, x: -10 }, // Initial state of the item
        visible: i => ({
            opacity: 1,
            x: 0,
            transition: {
                delay: i * 0.4, // Delay each item based on its index
            },
        }),
    };

    return (
        <>
            <AnimatePresence>
                {locations && locations.length > 0 && locations.map((value, index) => (
                    <motion.div
                        key={index}
                        className='d-flex flex-row justify-content-start align-items-center my-3 btn btn-outline border border-warning'
                        variants={itemVariants}
                        initial="hidden"
                        animate="visible"
                        custom={index} // Pass the index as a custom prop to use in the variant
                    >
                        <span className='material-symbols-outlined me-3 text-warning'>location_on</span>
                        <span>{value}</span>
                    </motion.div>
                ))}
            </AnimatePresence>
        </>
    );
}

export default AreasDisplay;