import React, { useContext, useState } from "react";
import { formatDistanceToNow } from "date-fns";
import { enUS, es, ptBR } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { ProjectContext } from "../../context/ProjectContext";
import TabSummary from "./TabSummary";
import TabChat from "./TabChat";
import { ExpectedDate } from '../../data/dummyData';
import { ProviderContext } from "../../context/ProviderContext";
import TabEstimateProvider from "./TabEstimateProvider";



const RequestProviderCard = ({ request, getAllRequests = () => {} }) => {
  const { t, i18n } = useTranslation();
  const locales = { en: enUS, es: es , br: ptBR}; // Add more locales as needed
  const dateFnsLocale = locales[i18n.language] || enUS; // Default to English if locale not found
  const { updateUserRequest } = useContext(ProjectContext);
  const {provider, providerProfile} = useContext(ProviderContext)
  const [selectedTab, setSelectedTab]=useState(0)
  const expectedDates = ExpectedDate()

  // Ensure the request object and its nested properties exist before trying to access them

  // const handleContactClick = async () => {
  //   const response = await updateUserRequest({
  //     _id: request._id,
  //     status: "seen",
  //     seen: new Date(),
  //   });
  //   if (!response.error) {
  //     await getAllRequests();
  //   }
  // };

  const summaryData = request
    ? [
        {
          label: t("home_provider.service"),
          data: request.service?.label,
          icon: "handyman",
        },
        {
          label: t("home_provider.expected_date"),
          data: expectedDates[request.userProject.expected_date].label,
          icon: "event_available",
        },
        {
          label: t("home_provider.address"),
          data: request.userProject?.address?.formatted_address,
          icon: "pin_drop",
        },
        {
          label: t("home_provider.unit"),
          data: request.userProject?.unit,
          icon: "apartment",
        },
        {
          label: t("home_provider.notes"),
          data: request.userProject?.description,
          icon: "notes",
        },
      ]
    : [];

  const handleArchive = async () => {
    const response = await updateUserRequest({
      _id: request._id,
      status: "archived",
      archived: new Date(),
    });
    if (!response.error) {
      await getAllRequests();
    }
  };

  const handleLost = async () => {
    const response = await updateUserRequest({
      _id: request._id,
      won: false,
      wonAt: new Date(),
    });
    if (!response.error) {
      await getAllRequests();
    }
  };

  const handleWon = async () => {
    const response = await updateUserRequest({
      _id: request._id,
      won: true,
      wonAt: new Date(),
    });
    if (!response.error) {
      await getAllRequests();
    }
  };

  return (
    <div className="col-lg-6">
      <div className="card card-flush mb-6 h-100">
        <div className="card-header">
          <div className="d-flex flex-column">
            <h4 className="card-title fw-bold mc-color-1">
              {request.user.first_name} {request.user.last_name}
            </h4>
            <span className="text-muted fs-8">
              {formatDistanceToNow(new Date(request.requestedAt), {
                addSuffix: true,
                locale: dateFnsLocale,
              })}
            </span>
          </div>
          <div className="card-toolbar">
            
          </div>
        </div>
        <div className="card-body">
          <ul className="nav nav-tab nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-8 fw-bold">
                <li className="nav-item cursor-pointer" onClick={()=>setSelectedTab(0)}><span className={`nav-link ${selectedTab === 0 && 'active text-dark'}`}>{t("nav.request")}</span></li>
                <li className="nav-item cursor-pointer" onClick={()=>setSelectedTab(1)}><span className={`nav-link ${selectedTab === 1 && 'active text-dark'}`}>{t("nav.chat")}</span></li>
                <li className="nav-item cursor-pointer" onClick={()=>setSelectedTab(2)}><span className={`nav-link ${selectedTab === 2 && 'active text-dark'}`}>{t("nav.estimate")}</span></li>
                <li className="nav-item cursor-pointer" onClick={()=>setSelectedTab(3)}><span className={`nav-link ${selectedTab === 3 && 'active text-dark'}`}>{t("nav.payment")}</span></li>
                <li className="nav-item cursor-pointer" onClick={()=>setSelectedTab(4)}><span className={`nav-link ${selectedTab === 4 && 'active text-dark'}`}>{t("nav.review")}</span></li>
          </ul>

          <div className="border border-solid min-h-300px rounded pt-4 px-3 my-4">
                {selectedTab === 0 && <TabSummary data={summaryData}/>}
                {selectedTab === 1 && <TabChat receiverId={request.user_id} userRequestId={request._id} senderId={providerProfile._id} myId={providerProfile._id} token={provider.token}/>}
                {selectedTab === 2 && <TabEstimateProvider userRequestId={request._id} token={provider.token} setSelectedTab={setSelectedTab} />}
          </div>



          

          <div className="d-flex justify-content-end">
            <div
              onClick={() => handleArchive()}
              className="btn btn-sm btn-secondary  mx-1"
            >
              <span className="material-symbols-outlined fs-7 ">delete</span>{" "}
              {t("home_provider.archive")}
            </div>

            {request.won === undefined && (
              <>
                <span
                  onClick={() => handleLost()}
                  className="material-symbols-outlined btn btn-sm btn-icon btn-danger border border-danger  mx-1"
                >
                  thumb_down
                </span>

                <span
                  onClick={() => handleWon()}
                  className="material-symbols-outlined btn btn-sm btn-icon btn-success border border-success  mx-1"
                >
                  thumb_up
                </span>
              </>
            )}
            {request.won && (
              <div className="btn btn-sm btn-success">
                <span className="material-symbols-outlined fs-7">thumb_up</span>{" "}
                {t("home_provider.won")}
              </div>
            )}
            {request.won === false && (
              <div className="btn btn-sm btn-danger">
                <span className="material-symbols-outlined fs-7">
                  thumb_down
                </span>{" "}
                {t("home_provider.lost")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestProviderCard;
