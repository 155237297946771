import React from 'react';

const RibbonTitle = ( {title}) => {
    return ( 
        <div className="card-header"><h3 className="card-title" >{title}</h3>
        </div>

     );
}
 
export default RibbonTitle;