import React from "react";
import HeaderProvider from "../../components/common/HeaderProvider";
import { useTranslation } from "react-i18next";

const WhyMC = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderProvider title={t("terms.title")}>
        <div className="card card-flush">
          <div className="card-header">
            <div className="card-title">
              <h3>{t("terms.subtitle")}</h3>
            </div>
          </div>
          <div className="card-body">
            terminos aca...
          </div>
        </div>
      </HeaderProvider>
    </>
  );
};

export default WhyMC;
