import React , {useRef} from "react";
import {useInView} from 'framer-motion'
const MotionDiv = ({className, children }) => {

    const ref = useRef(null);
    const isInView = useInView(ref);

  return (
    <div
      ref={ref}
      className={className}
      style={{
        opacity: isInView ? 1 : 0,
        translateX: isInView ? 0 : 30,
        translateY: isInView ? 0 : 30,
        rotateY: isInView ? "0deg" : "-20deg",
        transition: "1.5s",
        scale: isInView ? 1 : 0.8,
      }}
    >
      {children}
    </div>
  );
};

export default MotionDiv;
