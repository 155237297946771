import React, { useContext, useState } from "react";
import { RequestContext } from "../../../context/RequestContext";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const Description = () => {
  const {t} = useTranslation()
  const { description, setContextDescription } = useContext(RequestContext);
  const [desc, setDesc] = useState(description);
  const handleChange = (value) => {
    setDesc(value)
    setContextDescription(value);
  };
  return (
    <div className="confirmation-fieldsTTT">
      <TextField
        multiline
        rows={4}
        variant="outlined"
        label={t("request.more_details")}
        size="large"
        fullWidth
        value={desc ? desc : ''}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};

export default Description;
