import React from "react";

const ServiceNav = ({
  current,
  index,
  title,
  description,
  icon,
  action = () => {},
}) => {
  return (
    <div
      className={`stepper-item ${
        current === index ? "current" : ""
      } d-flex flex-column`}
      onClick={() => action(index)}
    >
      <div className="stepper-wrapper w-100 d-flex flex-row ">
        <div className={`stepper-icon w-40px h-40px mc-btn-bg`} style={{minWidth: '40px'}}>
          <span className="stepper-number ">
            <span className="material-symbols-outlined">{icon}</span>
          </span>
        </div>
        <div className="stepper-label w-200px mx-8">
          <h3 className="stepper-title">{title}</h3>
          <div className="stepper-desc fw-semibold">{description}</div>
        </div>
      </div>
      <div className="stepper-line h-40px"></div>
    </div>
  );
};

export default ServiceNav;
