import React from "react";
import ClientRow from "./ClientRow";

const ClientList = ({ clients, setCurrentClient=()=>{} }) => {
  return (
    <>
      <div className="card card-flush">
        <div className="card-header">
          <h3 className="card-title">Client List</h3>
          <div className="card-toolbar">
            <div className="btn btn-sm btn-light-primary">Force Update</div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr className="text-muted">
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Second Contact</th>
                  <th>Address</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {clients &&
                  clients.map((user) => {
                    return (
                      <ClientRow
                        key={user._id}
                        user={user}
                        setCurrentClient={setCurrentClient}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientList;
