import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Rating } from "react-simple-star-rating";
import RespondReview from "./RespondReview";
import formatDistancetoNow from "date-fns/formatDistanceToNow";
import { enUS, es, ptBR } from "date-fns/locale";

const ReviewCard = ({ review , getReviews=()=>{}}) => {
  const { t, i18n } = useTranslation();
  const locales = { en: enUS, es: es , br: ptBR}; // Add more locales as needed
  const dateFnsLocale = locales[i18n.language] || enUS; // Default to English if locale not found
    const [respond, setRespond] = useState(false)

  return (
    <div className="card card-flush mb-6">
      <div className="card-header">
        <div className="card-title">
          <div className="d-flex flex-column">
            <h3>{review?.userDetails[0]?.first_name} {review?.userDetails[0]?.last_name}</h3>
            <small className="text-muted">{formatDistancetoNow(new Date(review.review_date), {
                addSuffix: true,
                locale: dateFnsLocale,
              })}</small>
          </div>
        </div>
        <div className="card-toolbar">
            <div className="request-card-stars">
              <Rating
                size={16}
                initialValue={review?.stars}
                readonly={true}
                allowFraction={true}
              />{" "}
              <span className="stars-label">{review.stars}</span>
            </div>
            
        </div>
      </div>
      <div className="card-body pt-1">{review?.review}
      <div>
        {review.review_response && <div className="mt-6"><span className="badge badge-secondary ">{t("request.your_response")}</span><div className="rounded bg-light p-6 w-100 text-gray-700">{review.review_response} </div></div>}
      {!respond && !review.review_response && <div onClick={()=>setRespond(v=>!v)} className="btn btn-light-warning btn-sm mt-6"><span className="material-symbols-outlined fs-7 me-2">reviews</span>{t("request.respond_review")}</div>}
      {respond && !review.review_response && <RespondReview userRequest={review._id} getReviews={getReviews}/>}
      </div></div>
    </div>
  );
};

export default ReviewCard;
