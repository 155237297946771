import React from "react";

const LoadingApp = () => {
    const color = ''
  return (
    <div style={{height: '100vh'}} className="bg-secondary">
      <div className=" d-flex justify-content-center align-items-center">
        <img style={{width:'30%', maxWidth: '100px', paddingTop:'45vh',opacity:'0.3', filter:'grayscale(100%)' }} src={require(`../images/logo/mc-${color}.png`)} />
      </div>
    </div>
  );
};

export default LoadingApp;
