import React from "react";
import CategoryBox from "../CategoryBox";
import { useMainCategories } from "../../data/dummyData";

const HomeCategories = ({ categories_title , edit}) => {
  const mainCategories = useMainCategories()
  return (
    <div className="categories-wrap">
      <h2>{categories_title}</h2>
      <div className="main-categories">
        {mainCategories.map((cat,index) => (
          <CategoryBox edit={edit} key={cat._id} category={cat} index={index}/>
        ))}
      </div>
    </div>
  );
};

export default HomeCategories;
