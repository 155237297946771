import React from "react";
import LanguageSwitcher from "./LanguageSwitcher";


const Footer = () => {
  return (
    <>
      <div className="footer">
        <LanguageSwitcher />
      </div>
    </>
  );
};

export default Footer;
