import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion'
import formatDistancetoNow from "date-fns/formatDistanceToNow";
import { enUS, es, ptBR } from "date-fns/locale";

import ItemPricingData from './ItemPricingData';


const EstimateView = ({ estimate, setSelectedTab }) => {
  const { t } = useTranslation()
  const { i18n } = useTranslation();
  const locales = { en: enUS, es: es, br: ptBR }; // Add more locales as needed
  const dateFnsLocale = locales[i18n.language] || enUS; // Default to English if locale not found

  const itemVariants = {
    hidden: { opacity: 0, x: -10 }, // Initial state of the item
    visible: i => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2, // Delay each item based on its index
      },
    }),
  };

  const buttonVariant = {
    hidden: { opacity: 0, x: 0, scale: 1.3 }, // Initial state of the item
    visible: i => ({
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        delay: i * 0.2, // Delay each item based on its index
      },
    }),
  };

  const summaryData = estimate
    ? [
      {
        label: t("estimate.labour"),
        data: estimate?.labour_desc,
        icon: "engineering",
        price: (estimate?.labour_value || 0)
      },
      {
        label: t("estimate.supplies"),
        data: estimate?.supplies_desc,
        icon: "package_2",
        price: estimate?.supplies_value || 0
      },
      {
        label: t("estimate.others"),
        data: estimate?.others_desc,
        icon: "info",
        price: estimate?.others_value || 0
      },
      {
        label: t("estimate.taxes"),
        data: estimate?.taxes_desc,
        icon: "account_balance",
        price: estimate?.taxes_value || 0
      },
      {
        label: t("estimate.sent"),
        data: estimate.sent && formatDistancetoNow(new Date(estimate.sent), {
          addSuffix: true,
          locale: dateFnsLocale,
        }),
        icon: "event_available",
      },
      {
        label: t("estimate.expiry_date"),
        data: !estimate.accepted && formatDistancetoNow(new Date(estimate?.expiry_date), {
          addSuffix: true,
          locale: dateFnsLocale,
        }),
        icon: "event_available",
      },
      {
        label: t("estimate.total"),
        data: t("estimate.total"),
        icon: "event_available",
        price: (parseFloat(estimate?.labour_value || 0) + parseFloat(estimate?.supplies_value || 0) + parseFloat(estimate?.others_value || 0) + parseFloat(estimate?.taxes_value || 0)),
      },
    ]
    : [];

  return (<div>

    {summaryData.length > 0 &&
      summaryData.map((item, index) => (
        <ItemPricingData
          key={`${item.label}-${index}`}
          data={item}
          index={index}
        />
      ))}
    {estimate && estimate.accepted && <motion.div
      variants={buttonVariant}
      initial="hidden"
      animate="visible"
      custom={8}

      className='btn btn-success btn-hover-light-success w-100 btn-sm'><span className='material-symbols-outlined fs-7 me-3'>thumb_up</span><span>{t("estimate.accepted")}</span></motion.div>}
    {estimate && estimate.accepted && !estimate.reserved && <motion.div
      variants={buttonVariant}
      initial="hidden"
      animate="visible"
      custom={15}

    ><p className='text-center my-3'>{t("estimate.last_step")}</p> <div className='d-flex justify-content-end'><span className='btn btn-mc btn-sm my-3' onClick={() => { setSelectedTab(3) }}>{t("estimate.go_to_pay")}</span></div></motion.div>}




  </div>);
}

export default EstimateView;