// EditServiceModal.js
import { TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';

const EditServiceModal = ({ show, onClose, service, onSave }) => {
  const [newService, setNewService] = useState(service);

  useEffect(() => {
    setNewService(service); // Reset form when service prop changes
  }, [service]);

  if (!show) {
    return null;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(newService);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewService((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className="modal-overlay"></div>
      <div className="modal" style={{ display: 'block', maxWidth: '70vw', left: '300px', top: '20vh' }}>
        <div className="modal-content">
          <span className="close material-symbols-outlined btn btn-sm btn-danger btn-icon close-modal" onClick={onClose}>close</span>
          <div className="card card-flush mb-6">
            <div className="card-header">
              <h3 className="card-title">Edit Service</h3>
            </div>
            <div className="card-body">
              <div className="container">
                <div className="row">
                  <TextFieldFields handleChange={handleChange} newService={newService} />
                  <div className="col-lg-1">
                    <span onClick={handleSubmit} className="material-symbols-outlined btn btn-icon btn-sm btn-primary">send</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TextFieldFields = ({ handleChange, newService }) => (
  <>
    <div className="col-lg-2">
      <TextField
        name="id"
        variant="outlined"
        label="id"
        value={newService?.id || ''}
        fullWidth
        onChange={handleChange}
      />
    </div>
    <div className="col-lg-3">
      <TextField
        name="label"
        variant="outlined"
        label="label"
        value={newService?.label || ''}
        fullWidth
        onChange={handleChange}
      />
    </div>
    <div className="col-lg-3">
      <TextField
        name="category"
        variant="outlined"
        label="category"
        value={newService?.category || ''}
        fullWidth
        onChange={handleChange}
      />
    </div>
    <div className="col-lg-3">
      <TextField
        name="subcategory"
        variant="outlined"
        label="subcategory"
        value={newService?.subcategory || ''}
        fullWidth
        onChange={handleChange}
      />
    </div>
  </>
);

export default EditServiceModal;
