import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestContext } from '../../../context/RequestContext';


const MoreProviders = () => {
    const { t } = useTranslation()
    const { moreProviders, setContextMoreProviders } = useContext(RequestContext)
    const [more, setMore] = useState(true)
    const handleSelect = (event) => {
        setMore(event.target.checked)
        setContextMoreProviders(event.target.checked)
    }

    useEffect(()=>{
        if(moreProviders === true || moreProviders === false){
            setContextMoreProviders(moreProviders)
            setMore(moreProviders)
        } else{
            setContextMoreProviders(true)
        }
    },[])

return (<div>
        <FormGroup>
            <FormControlLabel control={<Switch
                checked={more}
                onChange={handleSelect}
                inputProps={{ 'aria-label': 'controlled' }} />} label={t("request.more_providers")} />
        </FormGroup>
    </div>);
}

export default MoreProviders;