import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutBusiness = () => {
    const { t } = useTranslation();
    return (
      <div className="card card-flush">
        <div className="card-header">
          <h3 className="card-title">{t("home_provider.about_my_business")}</h3>
        </div>
        <div className="card-body">
          Add the region - License - Insurance - Photos - Description - estimate expire date default
        </div>
      </div>
    );
}
 
export default AboutBusiness;