import React, { useContext, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { RequestContext } from '../../context/RequestContext';

import ServiceRow from './ServiceRow';
import EditServiceModal from './EditServiceModal';

const ServiceList = () => {
  const { allServices, fetchAllServices, updateOneService } = useContext(RequestContext);
  const [filter, setFilter] = useState('');
  const [filteredServices, setFilteredServices] = useState([]);
  const [editingService, setEditingService] = useState(null); // Track the service being edited

  useEffect(() => {
    fetchAllServices(); // Ensure services are loaded
  }, [fetchAllServices]);

  useEffect(() => {
    // Apply filter whenever allServices or filter changes
    const filtered = allServices.filter(service => service.label.toLowerCase().includes(filter.toLowerCase()));
    setFilteredServices(filtered);
  }, [allServices, filter]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleEdit = (service) => {
    setEditingService(service); // Set the service to be edited and open modal
  };

  const saveService = async (updatedService) => {
    await updateOneService( updatedService);
    fetchAllServices(); // Refresh the list after saving
    setEditingService(null); // Close modal after saving
  };

  return (
    <>
      <div className="card card-flush mb-6">
        <div className="card-header">
          <h3 className="card-title">Service List</h3>
          <div onClick={fetchAllServices} className="card-toolbar">
            <div className="btn btn-sm btn-light-primary">Force Update</div>
          </div>
        </div>
        <div className="card-body">
          <div className="filter-container pb-6">
            <TextField
              type="text"
              label="Search by label..."
              value={filter}
              onChange={handleFilterChange}
              className="form-control"
            />
          </div>
          <div className="table-responsive">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr className="text-muted">
                  <th>ID</th>
                  <th>Label</th>
                  <th>Category</th>
                  <th>Subcategory</th>
                  <th style={{ minWidth: '90px' }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredServices.map((service) => (
                  <ServiceRow
                    key={service._id}
                    item={service}
                    onEdit={() => handleEdit(service)}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {editingService && (
        <EditServiceModal
          show={Boolean(editingService)}
          onClose={() => setEditingService(null)}
          service={editingService}
          onSave={saveService}
        />
      )}
    </>
  );
};

export default ServiceList;