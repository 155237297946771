import React, { useContext } from "react";
import { RequestContext } from "../../context/RequestContext";

const ServiceRow = ({ item, onEdit = () => {} }) => {

  const {deleteOneService} = useContext(RequestContext)

  return (
    <tr>
      <td><span className="btn btn-light disabled btn-disabled btn-sm btn-icon">{item.id}</span></td>
      <td>{item.label}</td>
      <td>{item.category}</td>
      <td>{item.subcategory}</td>
      <td style={{minWidth: '90px'}}>
        {/* Edit Button */}
        <span
          onClick={() => onEdit(item)}
          className="btn btn-icon btn-sm btn-light-primary material-symbols-outlined"
          style={{ marginRight: "8px" }} // Add some space between buttons
        >
          edit
        </span>
        {/* Delete Button */}
        <span
          onClick={() => deleteOneService(item._id)}
          className="btn btn-icon btn-sm btn-light-danger material-symbols-outlined"
        >
          delete
        </span>
      </td>
    </tr>
  );
};

export default ServiceRow;
