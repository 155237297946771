import React from "react";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { t } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div className="d-flex justify-content-between align-items-center ">
      <span className="">{t("footer.language")}</span>
      <div>
        <button
          className="btn btn-icon btn-active-dark btn-outline border border-dark mx-2"
          onClick={() => changeLanguage("en")}
        >
          EN
        </button>
        <button
          className="btn btn-icon btn-active-dark btn-outline border border-dark mx-2"
          onClick={() => changeLanguage("br")}
        >
          PT
        </button>
        <button
          className="btn btn-icon btn-active-dark btn-outline border border-dark mx-2"
          onClick={() => changeLanguage("es")}
        >
          ES
        </button>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
