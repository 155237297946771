import React, { useEffect, useState } from 'react';
import { getEstimate } from '../../api/api';
import EditEstimate from './EstimateEdit';
import EstimateView from './EstimateView';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const TabEstimateProvider = ({ token, userRequestId, color , setSelectedTab}) => {
    const [estimate, setEstimate] = useState(null)
    const [loading,setLoading]=useState(true)
    const [editing, setEditing]=useState(false)
    const {t} = useTranslation()

    const itemVariants = {
        hidden: { opacity: 0, x: -10 }, // Initial state of the item
        visible: i => ({
          opacity: 1,
          x: 0,
          transition: {
            delay: i * 0.2, // Delay each item based on its index
          },
        }),
      };

    const getCurrentEstimate = async () => {
        const myEstimate = await getEstimate({ userRequestId }, token)
        if (myEstimate && myEstimate._id) {
            setEstimate(myEstimate)
        }
        setLoading(false)
    }
    useEffect(() => {
        getCurrentEstimate()
    }, [userRequestId])

    return (<div className='overflow-scroll pt-2'>
        {loading && <motion.small
      variants={itemVariants}
      initial="hidden"
      animate="visible"
      
      className='btn btn-light d-block btn-sm '>{t("request.loading")}</motion.small>}
        {!loading && (editing || (!editing && !estimate)) && <EditEstimate estimate={estimate} token={token} userRequestId={userRequestId} getCurrentEstimate={getCurrentEstimate} setEditing={setEditing}/>}
        {!loading && !editing && estimate && <EstimateView estimate={estimate} color={color} setSelectedTab={setSelectedTab}/>}
        {!loading && !editing && estimate && !estimate.accepted && <motion.div
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            custom={3}
            className='d-flex flex-row justify-content-end my-3'>

                {setEditing && <motion.span
                    whileHover={{ scale: 0.98, rotate: '0.2deg' }}
                    whileTap={{ scale: 0.95, rotate: '0.4deg' }}
                    className='btn btn-sm btn-mc '
                    style={{ textAlign: 'center' }}
                    onClick={()=>setEditing(true)}
                >{t("estimate.edit")}
                </motion.span> }
        </motion.div>}
    </div>);
}

export default TabEstimateProvider;