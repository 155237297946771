import React, { useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { RequestContext } from '../../../context/RequestContext';
import { useTranslation } from "react-i18next";

const SearchFormTop = () => {
  const { t } = useTranslation();
  const {
    allServices,
    fetchAllServices,
    setContextService,
    service,
    removeService
  } = useContext(RequestContext);
  // Ensure initial state is not null or '', but undefined or a valid object
  const [serviceForm, setServiceForm] = useState(service !== "" ? service : null);

  useEffect(() => {
    fetchAllServices();
  }, [fetchAllServices]);

  const handleServiceChange = (event, newValue) => {
    if (newValue != null) {
      setServiceForm(newValue);
      setContextService(newValue);
    } else {
      setServiceForm(null); // Set to null if no service is selected
      removeService();
    }
  };

  return (
    <Autocomplete
      size="large"
      disablePortal
      id="combo-box-demo"
      options={allServices.filter(Boolean)} // Filter out null or undefined values
      getOptionLabel={(option) => option ? option.label || '' : ''} // Safely handle null or undefined options
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleServiceChange}
      value={serviceForm}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('request.select_service')}
          variant="outlined"
        />
      )}
    />
  );
};

export default SearchFormTop;