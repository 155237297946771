import React from "react";
import ClientData from "./ClientData";
import ClientProjects from "./ClientProjects";

const ClientDetails = ({ user, setCurrentClient = () => {} }) => {
  return (
    <>
    <ClientData user={user} setCurrentClient={setCurrentClient} />
    <ClientProjects user={user}/>
    
      
    </>
  );
};

export default ClientDetails;
