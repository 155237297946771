import React from "react";
import Navbar from "./NavbarClient";
import Footer from "./Footer";

const HeaderClient = ({ children, title }) => {
  return (
    <>
      <div className="bg-gray-900">
        <div className="inner-pages-title ">
          <Navbar color="white" />
        </div>
        <div className="outer-wrap">
          <div className="boxed">
            <div className="home-form pb-100 mh-75vh">
              <h2 className="text-white mb-8 mt--100">{title}</h2>
              <div className="row mb-8 mt--100 g-4">{children}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HeaderClient;
