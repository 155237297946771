import React from 'react';

const HomeAdmin = () => {
    return ( <>
    <div className="row gy-5 g-xl-8">
        <div className="col-lg-4">
            <div className="card card-flush">
                <div className="card-header">
                    <h3 className="card-title"> Home</h3>
                </div>
                <div className="card-body">

                </div>
            </div>
        </div>
    </div>
    </> );
}
 
export default HomeAdmin;