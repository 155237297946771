import React, { useContext, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { RequestContext } from '../../../context/RequestContext';
import { useTranslation } from "react-i18next";
import { usePlacesWidget } from "react-google-autocomplete";

const SearchAddressFormTop = () => {
    const { t } = useTranslation();
    const { address, setContextAddress } = useContext(RequestContext);
    const [localAddress, setLocalAddress] = useState("");

    useEffect(() => {
        // Initialize localAddress with address from context if available
        if (address && address.address) {
            setLocalAddress(address.address);
        }
    }, [address]);

    const handleAddressChange = (eventOrAddressObject) => {
        let newAddress;
        // Check if the argument is an event object (has a target property) or an address object
        if (eventOrAddressObject.target) {
            // It's an event object from typing in the TextField
            newAddress = eventOrAddressObject.target.value;
        } else {
            // It's an address object from selecting a Google Place
            newAddress = eventOrAddressObject.address;
            // Since we now have a full address, we can update the context with all the details
            setContextAddress({
                address: newAddress,
                lat: eventOrAddressObject.lat,
                lng: eventOrAddressObject.lng
            });
        }
        setLocalAddress(newAddress);
    };

    const { ref: materialRef } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        onPlaceSelected: (place) => {
            if (place && place.geometry && place.geometry.location && place.formatted_address) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                const address = place.formatted_address;
                handleAddressChange({ address, lat, lng });
            } else {
                console.error('Invalid place selected. Please choose an actual address.');
            }
        },
        inputAutocompleteValue: "new-password", // This helps in avoiding autofill
        options: {
            types: ["address"],
            componentRestrictions: { country: 'ar' },
        },
    });

    return (
        <div>
            <TextField
                size="large"
                fullWidth
                color="primary"
                variant="outlined"
                inputRef={materialRef}
                label={t("form.address")}
                value={localAddress}
                onChange={handleAddressChange}
                sx={{ width: '100%' }}
            />
        </div>
    );
}

export default SearchAddressFormTop;