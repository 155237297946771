import React, { useState } from 'react';
import { motion } from 'framer-motion'
import ReviewsList from '../common/ReviewsList';
import ClientRating from '../common/ClientRating';
import { useTranslation } from 'react-i18next';


const TabReview = ({ providerId, status, setStatus }) => {
    const [reviewToggle, setReviewToggle] = useState(false)
    const {t}=useTranslation()

    return (<div>
        <ReviewsList provider_id={providerId} />
        {reviewToggle && <ClientRating userRequest={status} setStatus={setStatus} />}
        {!reviewToggle &&<div onClick={() => setReviewToggle(value => !value)} className={`btn btn-sm btn-${reviewToggle ? `light-` : ''}warning  text-capitalize mx-1`}>
            <span className="material-symbols-outlined fs-7">
                rate_review
            </span>{" "}
            {t("request.write_review")}

        </div>}
    </div>
    )
}

export default TabReview;