import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { RequestContext } from "../context/RequestContext";
import {motion } from 'framer-motion'

const CategoryBox = ({ category, edit, index }) => {
  let navigate = useNavigate()
  const {setContextService} = useContext(RequestContext)

  const handleClick = () => {
    if(edit){ 
      setContextService({id:category._id,label:category.label})
      localStorage.setItem("service", JSON.stringify({id:category._id,label:category.label}))
      navigate('/request')
    }
  }

  return (
    <Grid item xs={4} className={`category-box-item ${edit? 'cursor-pointer':''}`}  onClick={handleClick}>
      <motion.div
      whileHover={{scale:0.98, rotate: '0.2deg'}}
      whileTap={{scale:0.95, rotate: '0.4deg' }}
      
      className="card card-shadow category-card" style={{backgroundImage: `url(${process.env.PUBLIC_URL + category.img}`, backgroundPosition: `center center`}} >
        
        <span className="material-symbols-outlined category-icon btn btn-icon btn-light-secondary" >{category.icon}</span>
        <span className="category-button fs-8 text-wrap" style={{ color: 'white' }}>{category.title}
        </span>
      </motion.div>
    </Grid>
  );
};

export default CategoryBox;
