import React, { useCallback, useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../context/ProjectContext";
import { Rating } from "react-simple-star-rating";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from 'framer-motion'

const ReviewsList = ({ provider_id }) => {
  const { retrieveReviews } = useContext(ProjectContext);
  const [reviews, setReviews] = useState([]);
  const { t } = useTranslation();

  useEffect(
    useCallback(() => {
      const getReviews = async () => {
        setReviews(await retrieveReviews(provider_id));
      };
      getReviews();
    }, [retrieveReviews]),
    [retrieveReviews]
  );

  const itemVariants = {
    hidden: { opacity: 0, x: -10 }, // Initial state of the item
    visible: i => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2, // Delay each item based on its index
      },
    }),
  };

  return (
    <div
      style={{
        maxHeight: "400px",
        overflowY: "scroll",
        overflow: "-moz-scrollbars-vertical",
      }}
    >
      {!reviews && <motion.small
      variants={itemVariants}
      initial="hidden"
      animate="visible"
      
      className='btn btn-light d-block btn-sm '>{t("request.loading")}</motion.small>}
      {reviews && reviews.length > 0 &&
        reviews.map((review, index) => {
          return (
            <motion.div
              variants={itemVariants}
              initial="hidden"
              animate="visible"
              custom={index}

              className="d-flex flex-column rounded p-3 border border-secondary my-3"
              key={index}
            >
              <div>
                <Rating
                  allowFraction={true}
                  transition={true}
                  size={14}
                  initialValue={review.stars}
                  readonly={true}
                />{" "}
                <small className="text-muted fw-bold mx-2">{review.stars} {t("request.out_of_5_stars")}</small>
              </div>
              {review && review.review && <><motion.div
                variants={itemVariants}
                initial="hidden"
                animate="visible"
                custom={index +  2}
                className="mt-4">
                <div className="badge badge-light-warning">
                  {t("request.client_review")}
                </div>
              </motion.div>
                <motion.div
                variants={itemVariants}
                initial="hidden"
                animate="visible"
                custom={index + 3}
                className="p-4 rounded bg-light mb-3">{review.review}</motion.div></>}

              {review && review.review_response && (
                <>
                  <motion.div
                  variants={itemVariants}
                  initial="hidden"
                  animate="visible"
                  custom={index + 4 } className="mt-2">
                    <div className="badge badge-light-info">
                      {t("request.provider_response")}
                    </div>
                  </motion.div>
                  <motion.div
                  variants={itemVariants}
                  initial="hidden"
                  animate="visible"
                  custom={index + 4}
                   className="p-4 rounded bg-light mb-3">
                    {review.review_response}
                  </motion.div>
                </>
              )}
            </motion.div>
          );
        })}
    </div>
  );
};

export default ReviewsList;
