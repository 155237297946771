import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { cardAnimation } from "../../utils/HomeUtils";

const PlanCard = ({ plan }) => {
  const { t } = useTranslation();

  const planLabels = [
    { label: t("plan.listings"), icon: "list_alt" },
    { label: t("plan.leads"), icon: "recent_actors" },
    { label: t("plan.details"), icon: "info" },
  ];

  const itemVariants = {
    hidden: { opacity: 0, x: -10 }, // Initial state of the item
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.4, // Delay each item based on its index
      },
    }),
  };

  return (
      <motion.div
        className="col-xxl-6"
        initial={cardAnimation.initial}
        animate={cardAnimation.animate}
        transition={cardAnimation.transition}
      >
        <div className="card card-custom card-stretch gutter-b mb-3">
          <div className="card-header border-0">
            <h3 className="card-title font-weight-bolder text-dark text-capitalize">
              {plan.name}
            </h3>
            <div className="card-toolbar">
              <span className="btn btn-light-primary ">
                {plan.price}
              </span>
            </div>
          </div>
          <div className="card-body pt-2">
            <ul className="list-unstyled text-muted">
              {plan.features.map((feature, index) => (
                <motion.li
                  key={index}
                  variants={itemVariants}
                  initial="hidden"
                  animate="visible"
                  custom={index}
                >
                  <div className="w-100 mb-1 shadow d-flex flex-column rounded mb-6 p-3">
                    <div className="text-warning material-symbols-outlined text-center my-2">
                      {planLabels[index].icon}
                    </div>
                    <small className="text-muted text-center text-capitalize my-2">
                      {planLabels[index].label}
                    </small>
                    <div className="fw-bold text-center my-2 text-dark ">
                      {feature}
                    </div>
                  </div>
                </motion.li>
              ))}
            </ul>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <motion.span 
            whileHover={{
              scale:0.98,
              rotate:'2deg'
            }}
            whileTap={{
              scale:0.94,
              rotate:'4deg'
            }}
            className="btn btn-mc btn-sm">
              {t("plan.choose_plan")}
            </motion.span>
          </div>
        </div>
      </motion.div>
  );
};

export default PlanCard;
