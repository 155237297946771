import React, { useContext, useRef } from "react";
import CardListItem from "../CardListItem";
import { AuthContext } from "../../context/AuthContext";
import CtaButton from "../common/CTAButton";
import { useTranslation } from "react-i18next";
import { CurrentContext } from "../../context/CurrentContext";
import { ProviderContext } from "../../context/ProviderContext";
import {useInView} from 'framer-motion'



const HowItWorks = ({ title, description, steps }) => {
  const ref = useRef(null)
  const isInView = useInView(ref)
  const {t} = useTranslation()
  const { user } = useContext(AuthContext);
  const {isClient} = useContext(CurrentContext);
  const {provider} = useContext(ProviderContext)

  return (
    <div ref={ref}
    style={{
      opacity: isInView? 1: 0,
      transition: '2s',
    }}

    className="border boder-gray-700 card card-flush rounded shadow">
      <div className="card-header">
        <h3 className="card-title">{title}</h3>
      </div>
      <div className="card-body">
        <p className="">{description}</p>
        <div className="row">

        {steps.map((item, index) => {
          return <CardListItem key={index} item={item} index={index} />;
        })}
        </div>
        {isClient && user &&
          <CtaButton
            title={t('home_client.cta_button_logged.title')}
            icon={t('home_client.cta_button_logged.icon')}
            to="/request"
          />}
          {isClient && !user && 
          <CtaButton
            title={t('home_client.cta_button_not_logged.title')}
            icon={t('home_client.cta_button_not_logged.icon')}
            to="signup"
          />}
          {!isClient && !provider &&
          <CtaButton
            title={t('home_client.cta_button_not_logged.title')}
            icon={t('home_client.cta_button_not_logged.icon')}
            to="/signupproviders"
          />
        }
      </div>
    </div>
  );
};

export default HowItWorks;
