import React, {  useState } from 'react';
import { Box, TextField } from '@mui/material';
import { sendMessage } from '../../api/api';

// Modified to accept props, specifically onSendMessage
function SendMessageForm({ getMessages, userRequestId, receiverId , senderId, token }) {
    const [message, setMessage] = useState('');

    const handleSendMessage = async () => {
        const messageData = {
            userRequestId,
            senderId,
            receiverId,
            text: message,
        };

        try {
            await sendMessage(messageData, token);
            setMessage('');
            getMessages()
            // Optionally, trigger a refresh of messages here if not using WebSocket or similar for real-time updates
        } catch (error) {
            console.error('Failed to send message:', error);
        }
    };

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter' && !e.shiftKey) { // Check if Enter key is pressed without the shift key
            e.preventDefault(); // Prevent default action (e.g., form submission)
            await handleSendMessage();
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <div className='row d-flex flex-row align-items-center'>
                <div className='col-9'>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Type a message..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div className='col-3'>
                    <span onClick={handleSendMessage} className='btn btn-mc btn-xl btn-icon material-symbols-outlined'>
                        send
                    </span>
                </div>
            </div>
        </Box>
    );
}

export default SendMessageForm;