import React from 'react';
// import io from 'socket.io-client';
// import axios from 'axios';
import { Box } from '@mui/material';
import MessageList from './MessageList';
import SendMessageForm from './SendMessageForm';

function ChatBox({ getMessages, messages, userRequestId, senderId, token, receiverId , myId}) {
    return (
        <Box >
            <MessageList messages={messages} token={token} senderId={senderId} myId={myId}/>
            <SendMessageForm  getMessages={getMessages} userRequestId={userRequestId} receiverId={receiverId} token={token} senderId={senderId}/>
        </Box>
    );
}

export default ChatBox;