import { useTranslation } from "react-i18next";


export const useMainCategories = () => {
  const { t } = useTranslation();
  const categoryTitle = t("home_client.categories", { returnObjects: true })
  const categoryLabel = t("home_client.labels", { returnObjects: true })
  
  const mainCategories = [
    {
      title: categoryTitle[0],
      label: categoryLabel[0],
      img: "/images/categories/aire-acondicionado-reparo.jpg",
      _id: 2,
      id: 2,
      icon: "air",
      color: "#7239ea",
    },
    {
      title: categoryTitle[1],
      label: categoryLabel[1],
      img: "/images/categories/limpieza.jpg",
      _id: 64,
      id: 64,
      icon: "vacuum",
      color: "#c9e4de",
    },
    {
      title: categoryTitle[2],
      label: categoryLabel[2],
      img: "/images/categories/plumber.jpg",
      _id: 103,
      icon: "valve",
      color: "#f7d9c4",
    },
    {
      title: categoryTitle[3],
      label: categoryLabel[3],
      img: "/images/categories/electrician.webp",
      _id: 42,
      id: 42,
      icon: "electrical_services",
      color: "#c6def1",
    },
    {
      title: categoryTitle[4],
      label: categoryLabel[4],
      img: "./images/categories/moving.jpeg",
      _id: 91,
      id: 91,
      icon: "local_shipping",
      color: "#dbcdf0",
    },
  ];
  return mainCategories;
};

export const ExpectedDate = () => {
  const {t} = useTranslation()
  const expectedDateArray = t("request.options", { returnObjects: true })
  
  const myExpectedDate = [
    { id: 1, label: expectedDateArray[0] },
    { id: 2, label: expectedDateArray[1] },
    { id: 3, label: expectedDateArray[2] },
    { id: 4, label: expectedDateArray[3] },
  ];

  return myExpectedDate
}