import React, { useContext } from "react";
import SearchFormTop from "../common/RequestForm/SearchFormTop";
import {motion} from 'framer-motion'
import { useNavigate } from "react-router-dom";
import { RequestContext } from "../../context/RequestContext";
import SearchAddressFormTop from "../common/RequestForm/SearchAddressFormTop";

const HomeForm = ({ search_title, search_button }) => {
  let navigate = useNavigate();
  const { service, address } = useContext(RequestContext);

  const handleTopRequest = () => {
    if (service) {
      localStorage.setItem("service", JSON.stringify(service));
    }
    if (address) {
      localStorage.setItem("address", JSON.stringify(address));
    }
    if (service && address) {
      navigate("/request");
    }
  };

  return (
    <div className="home-form">
      <div
      
      className="card card-flush card-shadow shadow">
        <div className="card-header">
          <h3 className="card-title">{search_title}</h3>
        </div>

        <div className="card-body">
          <div className="container px-0">
            <div className="row px-2">
              <div className="col-lg-6 py-3 px-4">
                <SearchFormTop />
              </div>
              <div className="col-lg-6 py-3 px-4">
                <SearchAddressFormTop />
              </div>
              <div className="row px-2">
                <div className="col-lg-12 col-12 py-3 ms-3">

                <motion.span
                whileHover={{scale: 0.98, rotate: '0.2deg'}}
                whileTap={{scale: 0.95, rotate: '0.4deg'}}
                  className="btn-mc d-block text-hover-white btn btn-lg"
                  onClick={handleTopRequest}
                  >
                  {" "}
                  {search_button} &nbsp;{" "}
                  <span className="material-icons md-light ml-3 fs-5">search</span>
                </motion.span>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeForm;
