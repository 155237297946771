import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AuthContext } from "./AuthContext";
import { ProviderContext } from "./ProviderContext";
import useFetchWithAuth from '../hooks/useFetchWithAuth'

export const ProjectContext = createContext(null); // Initialized with null for clarity



const ProjectContextProvider = ({ children }) => {
  const [currentProject, setContextCurrentProject] = useState(null); // Initialize with null for clarity
  const [projects, setContextProjects] = useState([]);
  const { user, logout } = useContext(AuthContext);
  const { providerProfile, provider } = useContext(ProviderContext);

  useEffect(() => {
    if (user) {
      const myProject = localStorage.getItem("currentProject");
      if (myProject) {
        setContextCurrentProject(myProject);
      }
      const projectArray = JSON.parse(localStorage.getItem("userProjects"));
      if (projectArray) {
        setContextProjects(projectArray);
      }
    }
  }, [user]);

  const fetchWithAuth = useFetchWithAuth();

  const setProjects = async () => {
    if (user) {
      try {
        const response = await fetchWithAuth("/api/userProject", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
        if (!response.ok) {
          //if jwt expired let's logout
          const jsonError = await response.json();
          if (jsonError.jwt === false) {
            logout();
          }
        }
        const json = await response.json();
        setContextProjects(json);
        localStorage.setItem("userProjects", JSON.stringify(json));
      } catch (error) {
        console.error("Error fetching user projects:", error);
      }
    }
  };

  const updateUserRequest = async (body) => {
    //body = _id, status, stars, review, won, contacted
    const token = (provider && provider.token) ? provider.token : user.token;
    if (!body || !body._id) throw new Error("_id field is required");
    try {
      const response = await fetchWithAuth(`/api/userRequestUpdate`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });
      if (response.ok) {
        const json = await response.json();
        return json;
      }
    } catch (error) {

      console.log(error)
      
      return { error: error };
    }
  };

  const removeOneProject = async (projectid) => {
    if (!user) return; // Early return if user is not defined
    try {
      const response = await fetchWithAuth(`/api/userProject/${projectid}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (!response.ok) throw new Error("Failed to delete project");
      setContextProjects((prevProjects) =>
        prevProjects.filter((value) => value._id !== projectid)
      );
      // Update the localStorage after filtering
      localStorage.setItem(
        "userProjects",
        JSON.stringify(projects.filter((value) => value._id !== projectid))
      );
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  const returnProjects = async (token, user_id) => {
    try {
      const response = await fetchWithAuth(`/api/userProject/master/${user_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const jsonData = await response.json(); // Corrected method call
        return jsonData;
      }
    } catch (error) {
      console.error(error); // Consider how you want to handle/display this error
      return error;
    }
  };

  const retrieveProjectOptions = async (user_project_id, token) => {
    if (!user) return;
    try {
      const response = await fetchWithAuth(
        `/api/userProviderProject/providersCloseBy/${user_project_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to retrieve project options");
      const json = await response.json();
      return json;
    } catch (error) {
      console.error("Error retrieving project options:", error);
      // Consider how to handle/display this error properly
      return null; // Returning null or a similar approach might be more consistent
    }
  };

  const retrieveReviews = useCallback(
    async (provider_id) => {
      if (!providerProfile && !provider_id) return;
      const providerId = provider_id ? provider_id : providerProfile._id;
      const token = provider_id? user.token : provider.token;
      try {
        const response = await fetchWithAuth(`api/userRequest/getReviews/${providerId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if(response.ok){
          const json = await response.json()
          return json.reviews
        }
      } catch (error) {
        return error;
      }
    },
    [provider,providerProfile,user]
  );

  const getProvidersRequests = useCallback(async () => {
    if (!provider) return;
    try {
      const response = await fetchWithAuth(
        `/api/userRequest/providerLatestRequests/${providerProfile._id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${provider.token}`,
          },
        }
      );
      if (response.ok) {
        const json = await response.json();
        return json;
      } else {
        throw new Error("no response from server");
      }
    } catch (error) {
      return error;
    }
  }, [provider, providerProfile]);

  const removeCurrentProject = () => {
    localStorage.removeItem("currentProject");
    setContextCurrentProject(null); // Explicitly setting to null
  };

  const logoutProjects = () => {
    localStorage.removeItem("userProjects");
    localStorage.removeItem("currentProject");
    setContextProjects([]);
    setContextCurrentProject(null); // Explicitly setting to null
  };

  const setCurrentProject = (value) => {
    localStorage.setItem("currentProject", value);
    setContextCurrentProject(value);
  };

  return (
    <ProjectContext.Provider
      value={{
        currentProject,
        setCurrentProject,
        removeCurrentProject,
        removeOneProject,
        projects,
        setProjects,
        logoutProjects,
        returnProjects,
        retrieveProjectOptions,
        getProvidersRequests,
        updateUserRequest,
        retrieveReviews,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;
