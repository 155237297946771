import React, { useContext } from "react";
import { Routes, Route, Navigate , useLocation} from "react-router-dom";
import Signup from "./pages/Clients/Signup";
import Login from "./pages/Clients/Login";
import LoginProviders from "./pages/Providers/LoginProviders";
import HomeClient from "./pages/Clients/HomeClient";
import RequestFlow from "./pages/Clients/RequestFlow";
import BottomNav from "./components/common/BottomNav";
import RequestOptions from "./pages/Clients/RequestOptions";
import UserProjects from "./pages/Clients/UserProjects";
import UserProfile from "./pages/Clients/UserProfile";
import HomeProviders from "./pages/Providers/HomeProviders";
import SignupProviders from "./pages/Providers/SignupProviders";
import ProviderProfile from "./pages/Providers/ProviderProfile";
import RequestProviders from "./pages/Providers/RequestProviders";
import ReviewsProviders from "./pages/Providers/ReviewsProviders";
import WhyMC from "./pages/Providers/WhyMc";
import HomeProvidersLogged from "./pages/Providers/HomeProvidersLogged";
import LoginMaster from "./pages/Master/LoginMaster";
import DashboardMaster from "./pages/Master/DashboardMaster";
import BottomNavProvider from "./components/common/BottomNavProviders";
import { AuthContext } from "./context/AuthContext";
import { ProviderContext } from "./context/ProviderContext";
import { CurrentContext } from "./context/CurrentContext";
import { MasterContext } from "./context/MasterContext";

function App() {
  const { user } = useContext(AuthContext);
  const { provider } = useContext(ProviderContext);
  const { isClient } = useContext(CurrentContext);
  const { master } = useContext(MasterContext);
  const location = useLocation()

  return (
    <div className="App">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<HomeClient />} />
        <Route path="/homeproviders" element={!provider ? <HomeProviders /> : <HomeProvidersLogged />} />
        <Route path="/request" element={<RequestFlow />} />
        <Route path="/userprojects" element={!user ? <Signup /> : <UserProjects />} />
        <Route path="/signup" element={!user ? <Signup /> : <Navigate to="/" />} />
        <Route path="/signupproviders" element={!provider ? <SignupProviders /> : <RequestProviders />} />
        <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
        <Route path="/whymc" element={<WhyMC />} />
        <Route path="/loginproviders" element={!provider ? <LoginProviders /> : <ProviderProfile />} />
        <Route path="/reviewsproviders" element={provider ? <ReviewsProviders /> : <WhyMC />} />
        <Route path="/requestproviders" element={provider ? <RequestProviders /> : <SignupProviders />} />
        <Route path="/requestoptions" element={!user ? <Login /> : <RequestOptions />} />
        <Route path="/userprofile" element={!user ? <Login /> : <UserProfile />} />
        <Route path="/homeproviderlogged" element={provider ? <HomeProvidersLogged /> : <HomeProviders />} />
        <Route path="/masteraccess" element={!master ? <LoginMaster /> : <DashboardMaster />} />
        <Route path="/dashboardmaster" element={master ? <DashboardMaster /> : <LoginMaster />} />
        <Route path="/providerprofile" element={!provider ? <LoginProviders /> : <ProviderProfile />} />
      </Routes>
      {isClient && !master && <BottomNav />}
      {!isClient && !master && <BottomNavProvider />}
    </div>
  );
}

export default App;