import { TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Rating } from "react-simple-star-rating";
import { ProjectContext } from "../../context/ProjectContext";

const ClientRating = ({
  userRequest,
  setReviewToggle = () => {},
  setStatus = () => {},
}) => {
  const [stars, setStars] = useState(userRequest.stars);
  const [review, setReview] = useState(userRequest.review);

  const { updateUserRequest } = useContext(ProjectContext);

  const emoji = [
    "sentiment_very_dissatisfied",
    "sentiment_dissatisfied",
    "sentiment_neutral",
    "sentiment_satisfied",
    "sentiment_very_satisfied",
  ];

  const sendRatings = async () => {
    const review_date = new Date()
    const response = await updateUserRequest({
      _id: userRequest._id,
      stars,
      review,
      review_date
    });
    if (response) {
      setStatus({
        status: response.status,
        requestedAt: response.requestedAt,
        contacted: response.contacted,
        seen: response.seen,
        wonAt: response.wonAt,
        _id: response._id,
        stars: response.stars ? response.stars : null,
        review: response.review ? response.review : "",
      });
      if(response && response.error){
        //console.log(response.error)
      }
      setReviewToggle();
    }
    //console.log(response);
  };
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center py-10">
        <Rating
          allowFraction={true}
          transition={true}
          size={30}
          initialValue={stars}
          onClick={(value) => {
            setStars(value);
          }}
        />
        <span className="fw-bold fs-6 text-gray-600">
          {stars} {t("request.out_of_5_stars")}
        </span>
        <span className="material-symbols-outlined text-warning">
          {emoji[(Math.round(stars - 1))]}
        </span>
      </div>
      <TextField
        multiline
        rows={4}
        variant="outlined"
        label={t("request.write_review")}
        size="large"
        fullWidth
        value={review}
        onChange={(e) => setReview(e.target.value)}
      />
      {userRequest && userRequest.review_response && <div className="d-flex flex-column mt-4">
          <div><div className="badge badge-light-info">{t("request.provider_response")}</div></div>
          <div className="bg-light p-3 rounded">{userRequest.review_response}</div>

      </div>}
      <div className="d-flex justify-content-end mt-6">
        <span onClick={() => sendRatings()} className="btn btn-mc btn-sm text-capitalize">
          {userRequest && userRequest.review ? t("request.change_review") : t("home_provider.save") }
        </span>
      </div>
    </div>
  );
};

export default ClientRating;
