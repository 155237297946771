import React, { useContext, useEffect } from "react";
import { RequestContext } from "../../context/RequestContext";
import ServiceList from "../../components/Admin/ServiceList";
import AddService from "../../components/Admin/AddService";
import AddServiceJson from "../../components/Admin/AddServiceJson";

const ServicesAdmin = () => {
  const { fetchAllServices,  } = useContext(RequestContext);

  // Assuming setAllServices is an asynchronous operation that fetches and sets services
  useEffect(() => {
    const fetchServices = async () => {
      try {
        // If setAllServices does not fetch services, replace this with the correct fetch operation
        await fetchAllServices(); // Ensure setAllServices is designed to fetch and update the services state
      } catch (error) {
        console.error("Failed to fetch services:", error);
      }
    };

    fetchServices();
  }, []);

  

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-lg-12">
          <AddService />
          <AddServiceJson />
          <ServiceList />
        </div>
      </div>
    </>
  );
};

export default ServicesAdmin;
