import React, { useContext, useState } from "react";
import { TextField } from "@mui/material";
import { RequestContext } from "../../context/RequestContext"; // Adjust the import path as necessary

const AddServiceJson = () => {
  const [json, setJson] = useState("");
  const { addSeveralServices, fetchAllServices } = useContext(RequestContext); // Assuming fetchAllServices is the correct method name

  const handleSend = async () => {
    try {
      const jsonParsed = JSON.parse(json);
      await addSeveralServices( jsonParsed);
      fetchAllServices(); // Refresh the list of services
    } catch (error) {
      console.error('Could not process or send JSON to the server:', error);
    }
  };

  return (
    <>
      <div className="card card-flush mb-6">
        <div className="card-header">
          <h3 className="card-title">Add Several Services Json</h3>
        </div>
        <div className="card-body">
          <div className="container">
            <div className="row">
              <div className="col-lg-11">
                <TextField
                  multiline
                  rows={4}
                  variant="outlined"
                  label="[{id,label,category,subcategory},...]"
                  value={json}
                  size="large"
                  fullWidth
                  onChange={(e) => setJson(e.target.value)}
                />
              </div>
              <div className="col-lg-1">
                <span onClick={handleSend} className="material-symbols-outlined btn btn-icon btn-sm btn-primary">send</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddServiceJson;