import React from 'react';

const ClientData = ({user , setCurrentClient=()=>{}}) => {
    return ( <>
    <div className="card card-flush mb-6">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
            <div className="d-flex flex-column">
              <h3 className="">{user?.first_name + " " + user?.last_name}</h3>
              <div className="d-flex flex-row">
                {user?.email && (
                  <a
                    href={`mailto:${user?.email}`}
                    className="text-hover-primary me-4"
                  >
                    <span className="material-symbols-outlined btn btn-sm btn-icon btn-disabled btn-secondary me-2">
                      contact_mail
                    </span>
                    <span> {user?.email}</span>
                  </a>
                )}
                {user?.phone && (
                  <a
                    href={`tel:${user?.phone}`}
                    className="text-hover-primary me-4"
                  >
                    <span className="material-symbols-outlined btn btn-sm btn-icon btn-disabled btn-secondary me-2">
                      smartphone
                    </span>
                    <span>{user?.phone}</span>
                  </a>
                )}
              </div>
            </div>

            <div className="card-toolbar">
              <div
                onClick={() => {
                  setCurrentClient(null);
                }}
                className="btn btn-sm btn-light-primary mx-3"
              >
                Back to List
              </div>
              <span className="material-symbols-outlined btn btn-sm btn-danger btn-icon">
                delete
              </span>
            </div>
          </div>
        </div>
      </div>
    </> );
}
 
export default ClientData;