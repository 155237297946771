import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ConfirmationBox = ({ icon, initialMessage = "Loading...", message, className, outTime = 2000 }) => {
    const [displayMessage, setDisplayMessage] = useState(initialMessage);

    // Background animation with a fill effect
    const backgroundVariants = {
        hidden: { width: 0, backgroundColor: '#f2f2f2' },
        visible: {
            width: '100%',
            backgroundColor: '#50cd89',
            transition: {
                duration: 1,
                ease: [0.22, 1, 0.36, 1], // Custom ease to start slow and then accelerate
            }
        }
    };

    const containerVariants = {
        hidden: { scale: 0, opacity: 0 },
        visible: { 
            scale: 1, 
            opacity: 1,
            transition: {
                delayChildren: 1, // Delay the animation of children to start after background fill
                staggerChildren: 0.3, // Stagger the animation of children
            }
        },
        exit: { 
            opacity: 0,
            transition: { duration: outTime / 1000 } // Convert milliseconds to seconds for Framer Motion
        }
    };

    const itemVariants = {
        hidden: { scale: 0, opacity: 0 },
        visible: {
            scale: 1,
            opacity: 1,
            transition: { duration: 0.5 }
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setDisplayMessage(message); // Change the message after the initial animation
        }, 1000); // Adjust the timing based on your animation

        const exitTimer = setTimeout(() => {
            // Placeholder for fade out if needed
        }, outTime);

        return () => {
            clearTimeout(timer);
            clearTimeout(exitTimer);
        };
    }, [message, outTime]);

    return (
        <div style={{ position: 'fixed', zIndex: 100, top: 0, left: 0, right: 0, height: '100vh', width: '100vw' }} className='d-flex align-items-center justify-content-center'>
            <AnimatePresence>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={containerVariants}
                    className={`rounded d-flex flex-column justify-content-center align-items-center`}
                    style={{ position: 'absolute', overflow: 'hidden', height: '60vw', width: '60vw' }}
                >
                    <motion.div
                        className="bg-fill-effect"
                        variants={backgroundVariants}
                        style={{ position: 'absolute', top: 0, left: 0, height: '100%', zIndex: -1 }}
                    />
                    <motion.span
                        className='material-symbols-outlined p-6 fs-2 text-center text-white mb-3'
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: '25%' }}
                        variants={itemVariants}
                    >
                        {icon}
                    </motion.span>
                    <motion.span
                        className='text-center d-block text-white mt-3 fs-3 fw-bolder'
                        variants={itemVariants}
                    >
                        {displayMessage}
                    </motion.span>
                </motion.div>
            </AnimatePresence>
        </div>
    );
}

export default ConfirmationBox;