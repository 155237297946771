import React, { useContext, useEffect, useState } from "react";
import { MasterContext } from "../../context/MasterContext";
import { ProjectContext } from "../../context/ProjectContext";
import ProjectLabel from "./ProjectLabel";
import { RequestContext } from "../../context/RequestContext";

const ClientProjects = ({ user }) => {
  const [projects, setProjects] = useState([]);

  const { master } = useContext(MasterContext);
  const { returnProjects } = useContext(ProjectContext);
  const { allServices  } = useContext(RequestContext);
  

  useEffect(() => {
    // Flag to manage the component's mounted status
    let isMounted = true;

    const retrieveProjects = async () => {
      if (master && user) {
        try {
          const projectData = await returnProjects(master.token, user._id);
          if (isMounted) {
            setProjects(projectData);
          }
        } catch (error) {
          // Handle any errors, such as by setting an error state (not shown)
          console.error("Failed to retrieve projects:", error);
        }
      }
    };

    retrieveProjects();

    // Cleanup function to set isMounted to false when the component unmounts
    return () => {
      isMounted = false;
    };
  }, [master, user, returnProjects]); // Added returnProjects as a dependency


  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-lg-5 hover-scroll-overlay-y pe-6 me-n6">
            <div className="card card-flush mb-6">
              <div className="card-header">
                <h3 className="card-title">Projects</h3>
              </div>
              <div className="card-body">
                {projects &&
                  projects.map((project) => {
                    const myService = allServices.find(
                        (value) => value.id === project.service_id
                      );
                      const label = myService ? myService.label : "";
                    return <ProjectLabel key={project._id} project={project} label={label}/>;
                  })}
              </div>
            </div>
          </div>
          <div className="col-lg-8"></div>
        </div>
      </div>
    </>
  );
};

export default ClientProjects;
