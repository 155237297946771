import React, { useContext, useState } from "react";
import { RequestContext } from "../../context/RequestContext";
import { TextField } from "@mui/material";

const AddService = () => {
  const [newService, setNewService] = useState({id: '', label: '', category: '', subcategory: ''});

  const handleSubmit = async () => {
    await addService(newService);
    setNewService({id: '', label: '', category: '', subcategory: ''})
  };

  const { addService } = useContext(RequestContext);

  return (
    <>
      <div className="card card-flush mb-6">
        <div className="card-header">
          <h3 className="card-title">Add Service</h3>
        </div>
        <div className="card-body">
          <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <TextField
                  variant="outlined"
                  label="id"
                  value={newService?.id}
                  size="large"
                  fullWidth
                  onChange={(e) => setNewService({...newService, id: e.target.value})}
                />
              </div>
              <div className="col-lg-3">
                <TextField
                  variant="outlined"
                  label="label"
                  value={newService?.label}
                  size="large"
                  fullWidth
                  onChange={(e) => setNewService({...newService, label: e.target.value})}
                />
              </div>
              <div className="col-lg-3">
                <TextField
                  variant="outlined"
                  label="category"
                  value={newService?.category}
                  size="large"
                  fullWidth
                  onChange={(e) => setNewService({...newService, category: e.target.value})}
                />
              </div>
              <div className="col-lg-3">
                <TextField
                  variant="outlined"
                  label="subcategory"
                  value={newService?.subcategory}
                  size="large"
                  fullWidth
                  onChange={(e) => setNewService({...newService, subcategory: e.target.value})}
                />
              </div>
              <div className="col-lg-1">
                <span onClick={handleSubmit} className="material-symbols-outlined btn btn-icon btn-sm btn-primary">send</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddService;
