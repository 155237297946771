import React, { useContext, useState } from "react";
import LogInTitle from "../../components/LogIn/LogInTitle";
import { Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import NavbarProvider from "../../components/common/NavbarProvider";
import { ProviderContext } from "../../context/ProviderContext";
import { useTranslation } from "react-i18next";
import Footer from '../../components/common/Footer'

const LoginProviders = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const { login } = useContext(ProviderContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await login(email, password);
    if (response) {
      setError(response.error);
    }
  };

  return (
    <div className="bg-gray-900 mh-75vh">
      <div className="home-provider-title">
        <NavbarProvider color="white" />
        <div className="inner-wrap">
          <div className="boxed">
            <LogInTitle />
          </div>
        </div>
      </div>
      <div className="inner-wrap mt--100 px-10">
        <div className="boxed">
          <div className="card card-flush">
            <div className="card-header">
              <h3 className="card-title">{t("nav.login")}</h3>
            </div>
            <div className="card-body">
              <form className="sign-in" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6 my-3">
                    <TextField
                      fullWidth
                      type="text"
                      autoComplete="email"
                      value={email}
                      label={t("profile.email")}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-lg-6 my-3">
                    <TextField
                      fullWidth
                      type="password"
                      autoComplete="current-password"
                      value={password}
                      label={t("profile.password")}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit(e);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-12 my-3">
                    <Button
                      fullWidth={true}
                      variant="contained"
                      margin="normal"
                      className="maestro-orange-button"
                      onClick={handleSubmit}
                    >
                      {t("nav.login")} &nbsp;{" "}
                      <span className="material-symbols-outlined">
                        person_check
                      </span>
                    </Button>
                  </div>
                </div>
                <div>{error}</div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-wrap">
        <div className="boxed">
      <div className="p-10 mb-100">
        <p className="pb-5">{t("login.signup_text")}</p>
        <Link to="/signupproviders">
          <Button
            fullWidth
            variant="contained"
            margin="normal"
            className="maestro-orange-button"
          >
            {t("signup.signup")} &nbsp;{" "}
            <span className="material-symbols-outlined">person_add</span>
          </Button>
        </Link>
      </div>
      </div>
      </div>
      <Footer/>
    </div>
  );
};

export default LoginProviders;
