import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { MasterContext } from "../../context/MasterContext";
import ClientList from "./ClientList";
import ClientDetails from "./ClientDetails";

const ClientAdmin = () => {
  const [clients, setClients] = useState([]);
  const { listUsers } = useContext(AuthContext);
  const { master } = useContext(MasterContext);
  const [currentClient, setCurrentClient] = useState(null);

  useEffect(() => {
    const retrieveClients = async () => {
      setClients(await listUsers(master.token));
      
    };
    retrieveClients();
  }, [listUsers, master]);

  return (
    <>
      {!currentClient ? (
        <ClientList clients={clients} setCurrentClient={setCurrentClient} />
      ) : (
        <ClientDetails user={currentClient} setCurrentClient={setCurrentClient} />
      )}
    </>
  );
};

export default ClientAdmin;
